import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import drawerIcon from '../assets/drawer-icon-1.svg';
import settingIcon from '../assets/settings.svg';
import chevronIcon from '../assets/chevron-left.svg'
import { IconButton } from '@mui/material';
import { Menu } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

function Checking({ state, setState }) {

    const [activeLink, setActiveLink] = useState("")
    const navigate = useNavigate()

    function handleSelect(link) {
        if (link === "Cerrar la sesión") {
            localStorage.removeItem("token")
            localStorage.removeItem("currentUser")
            window.location.reload()
        }
        setActiveLink(link)
        navigate(`/${link === "Datos Personales" ? "personalize" :
                link === "Mis pedidos" ? "pedidos" :
                    link === "Métodos de pago" ? "Metados" :
                        link === "Ubicaciones guardadas" ? "Ubicaciones" :
                            link === "Mis ubicaciones" ? "Ubicaciones1" : ""
            }`)
    }

    return (
        <div>
            {state && <div style={{backgroundColor:"#E52823", paddingTop:100}}>
                <div
                    onClick={() => setState(false)}
                    style={{ display: "flex", alignItems: "center", margin: "0 0 15px 10px" }}>
                    <img src={chevronIcon} alt="" />
                    <img src={settingIcon} alt="" />
                    <p style={{
                        color: "white",
                        fontSize: "20px",
                        fontWeight: "700",
                        margin: "0 0 0 10px"
                    }}>Configuración</p>
                </div>
                {['Datos Personales', 'Mis pedidos', 'Métodos de pago', 'Ubicaciones guardadas', 'Mis ubicaciones', 'Cerrar la sesión'].map((text, index) => (
                    <ListItem
                        key={text}
                        disablePadding
                        onClick={() => {
                            handleSelect(text)
                            setTimeout(() => {
                                setState(false)
                            }, 1000);
                        }} style={{
                            backgroundColor: activeLink === text ? "#F9B8B9" : "",
                        }}>
                        <ListItemButton>
                            {text !== 'Cerrar la sesión' && <ListItemIcon>
                                <img src={drawerIcon} alt="" />
                            </ListItemIcon>}
                            <ListItemText primary={text} style={{ color: activeLink === text ? "#E52823 !important" : "white", }} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </div>}
        </div>
    );
}

export default Checking;