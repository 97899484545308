import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Logo from "../assets/logo.png"
import Cart from "../assets/cart.png"
import Icon from "../assets/icon.png"
import LoginModal from './LoginModal';
import Sidebar from '../components/Sidebar'
import { useLocation, useNavigate } from 'react-router-dom';
import GlobalContext from '../context';
function Navbar(props) {

    const user = JSON.parse(localStorage.getItem("currentUser"))
    const drawerWidth = 240;
    const navItems = [
        { name: 'Inicio', iconName: Cart, link: "https://pollosvictorina.com.do/sabias-que/" },
        { name: 'Quienes somos', link: "https://pollosvictorina.com.do/sucursales/" },
        { name: user?.name ? user?.name : "Iniciar sesión", icon: "true", iconName: "AccountCircleIcon", auth: true }
    ];
    const { height, win } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [open, setOpen] = React.useState(false)
    const [state, setState] = React.useState(false)
    const { isAuth, setisAuth } = React.useContext(GlobalContext)

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const itemStyle = {
        color: 'var(--white-100, #FFF)',
        fontFamily: 'Gotham Black',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: 'normal',
    }

    const drawer = (
        <Box
            onClick={handleDrawerToggle}
            sx={{
                height: "100vh",
                textAlign: 'center',
                backgroundColor: "rgb(235, 37, 45)",
                color: "var(--white-100, #FFF)"
            }}
        >
            <Typography sx={{ my: 2 }}>
                <img className='img-fluid' width={150} height={68} src={Logo} alt="logo" />
            </Typography>
            <List>
                {navItems.map((item, index) => (
                    <ListItem key={index} style={{ padding: "0 20px 0 0" }}>
                        <ListItemButton sx={{ textAlign: "right" }}>
                            <ListItemText primary={item.name} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    const cartStyle = {
        width: "50px",
        height: "60px",
        backgroundColor: "rgba(239, 239, 239, 1)",
        filter: 'drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.25))',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "100%",
        cursor: "pointer"
    }

    const container = win !== undefined ? () => win().document.body : undefined;
    const location = useLocation()
    const navigate = useNavigate()
    const token = localStorage.getItem("token")

    React.useEffect(() => {
        if (isAuth === "NOT AUTHENTICATED") {
            setOpen(true)
        }
        if (isAuth === "") {
            setOpen(false)
        }
    }, [isAuth])
    return (
        <>
            <LoginModal value={open} />
            <Box sx={{ display: 'flex', width: "100%", position: "absolute" }}>
                {/* // <Box sx={{ display: 'flex', width: "100%", backgroundColor: '#EB252D', }}>  */}
                <CssBaseline />
                <AppBar position={height > 100 ? "fixed" : "sticky"} component="nav" style={{ padding: { sm: "0px", md: "5px 0px", lg: "5px 0" }, backgroundColor: location.pathname === "/" && height === 0 ? "transparent" : height > 100 ? "#EB252D" : "#EB252D", border: "none", boxShadow: "none" }}>
                    {/* <AppBar position='sticky' component="nav" className='nav_Padding' style={{  backgroundColor: '#EB252D' }}> */}
                    <Toolbar className='toolbar' >
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{ display: { sm: 'none' }, height: '106px' }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography
                            className='nav_image'
                            // variant="h6"
                            component="div"
                            sx={{ flexGrow: 1, display: { sm: 'block' } }}
                        >
                            <img style={{ cursor: "pointer" }} className='img-fluid' width={320} height={91} src={Logo} alt="logo" onClick={() => navigate("/")} />
                        </Typography>
                        <Box sx={{ flexGrow: 2, display: { xs: 'none', sm: 'flex' }, alignItems: "center", justifyContent: "space-around" }}>
                            {navItems.map((item) => (
                                <Typography component="div" className='navItem' key={item} sx={itemStyle}>
                                    <span
                                        onClick={() => {
                                            if (item?.auth) {
                                                if (token === null) {
                                                    setOpen(!open)
                                                }
                                                else if (token !== null) {
                                                    setState(!state)
                                                }
                                            }
                                            else {
                                                window.open(item?.link)
                                            }
                                        }}>
                                        <img width={26.5} height={26.5} className='img-fluid' src={Icon} />
                                        <span className='mx-2'>{item.name}</span>
                                    </span>
                                </Typography>
                            ))}
                            <Typography className='cart_img' component="div" style={{ cursor: "pointer" }}>
                                <img width={70} height={70} src={Cart} onClick={() => navigate("/Fourth")} />
                            </Typography>
                        </Box>
                    </Toolbar>
                </AppBar>
                <nav>
                    <Drawer
                        container={container}
                        variant="temporary"
                        open={mobileOpen}
                        anchor='right'
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{
                            display: { xs: 'block', sm: 'none' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                    >
                        {drawer}
                    </Drawer>
                </nav>

            </Box>
            <Sidebar state={state} setState={setState} />
        </>
    );
}

Navbar.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default Navbar;
