// import React, { useState } from 'react';
// import { loadStripe } from '@stripe/stripe-js';
// import {
//     PaymentElement,
//     Elements,
// } from '@stripe/react-stripe-js';
// import { Dialog, DialogTitle } from '@mui/material';

// const CheckoutForm = ({ handlePaymentSuccess }) => {
//     const [errorMessage, setErrorMessage] = useState(null);

//     const handleSubmit = async (event) => {
//         event.preventDefault();

//         // Your payment handling logic here
//     };

//     return (
//         <form onSubmit={handleSubmit}>
//             <PaymentElement />
//             <button type="submit" style={{
//                 backgroundColor: "#635BFF",
//                 border: "none",
//                 padding: "10px 70px",
//                 fontWeight: "500",
//                 color: "white",
//                 borderRadius: "10px",
//                 width: "100%",
//                 margin: "20px 0"
//             }}>
//                 Pay
//             </button>
//             {/* Show error message to your customers */}
//             {errorMessage && <div>{errorMessage}</div>}
//         </form>
//     );
// };

// const stripePromise = loadStripe('pk_test_51PDL8U2Kb7CbmLKZS0tlWVKXJyE3qQ0rFEelo2hQouuHdAn2ZLuHvdexeTFjOj4gjEr5Jqno7Kz54uMe8QadqWPx00LHWPYiMr');

// const PaymentForm = ({ handlePaymentSuccess }) => {
//     const [showPaymentForm, setShowPaymentForm] = useState(false);
//     const [clientSecret, setClientSecret] = useState(null);

//     const handleClick = async () => {
//         // Fetch clientSecret from server
//         const res = await fetch('https://stripe-payment-server-seven.vercel.app/create-payment-intent', {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//             },
//             body: JSON.stringify({
//                 amount: 100,
//                 currency: 'usd',
//             }),
//         });
//         const data = await res.json();
//         setClientSecret(data.client_secret);
//         setShowPaymentForm(true);
//     };

//     return (
//         <div>
//             <button onClick={handleClick} style={{ marginTop: 100 }}>Show Payment Form</button>
//             {showPaymentForm && clientSecret && (
//                 <Dialog
//                     open={true}
//                     aria-labelledby="responsive-dialog-title"
//                     className='dialog'
//                     PaperProps={{
//                         style: {
//                             borderRadius: "28px",
//                             background: 'var(--white-100, #FFF)',
//                             boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.25)',
//                             width: "100%",
//                             margin: "20px",
//                             height: "350px"
//                         },
//                     }}
//                 >
//                     <DialogTitle id="responsive-dialog-title">
//                         <Elements stripe={stripePromise} options={{ clientSecret }}>
//                             <CheckoutForm handlePaymentSuccess={handlePaymentSuccess} />
//                         </Elements>
//                     </DialogTitle>
//                 </Dialog>
//             )}
//         </div>
//     );
// };

// export default PaymentForm;

// PaymentForm.js

// import React, { useState } from 'react';
// import { loadStripe } from '@stripe/stripe-js';
// import {
//     Elements,
//     PaymentElement,
// } from '@stripe/react-stripe-js';

// const stripePromise = loadStripe('pk_test_51PDL8U2Kb7CbmLKZS0tlWVKXJyE3qQ0rFEelo2hQouuHdAn2ZLuHvdexeTFjOj4gjEr5Jqno7Kz54uMe8QadqWPx00LHWPYiMr');

// const PaymentForm = () => {
//     const [showPaymentForm, setShowPaymentForm] = useState(false);
//     const [clientSecret, setClientSecret] = useState('');

//     const handleClick = async () => {
//         // Fetch clientSecret from server
//         const response = await fetch('https://stripe-payment-server-seven.vercel.app/create-payment-intent', {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//             },
//             body: JSON.stringify({
//                 amount: 100, // Adjust as needed
//                 currency: 'usd', // Adjust as needed
//             }),
//         });
//         const data = await response.json();
//         setClientSecret(data.clientSecret);
//         setShowPaymentForm(true);
//     };

//     const CheckoutForm = () => {
//         const [errorMessage, setErrorMessage] = useState(null);

//         const handleSubmit = async (event) => {
//             event.preventDefault();

//             const cardElement = event.target.querySelector('card-element'); // Assuming you have a <card-element> in your form

//             try {
//                 const { error, paymentMethod } = await stripePromise.confirmCardPayment(clientSecret, {
//                     payment_method: {
//                         card: cardElement,
//                     },
//                 });

//                 if (error) {
//                     setErrorMessage(error.message);
//                 } else if (paymentMethod) {
//                     // Payment succeeded
//                     console.log('Payment successful:', paymentMethod);
//                     // Perform any additional actions (e.g., redirecting)
//                 }
//             } catch (error) {
//                 console.error('Error processing payment:', error);
//                 setErrorMessage('Failed to process payment');
//             }
//         };

//         return (
//             <form onSubmit={handleSubmit}>
//                 <PaymentElement />
//                 <button type="submit">
//                     Pay
//                 </button>
//                 {/* Show error message to your customers */}
//                 {errorMessage && <div>{errorMessage}</div>}
//             </form>
//         );
//     };

//     return (
//         <div>
//             {!showPaymentForm ? (
//                 <button onClick={handleClick}>Show Payment Form</button>
//             ) : (
//                 <div>
//                     <Elements stripe={stripePromise} options={{ clientSecret }}>
//                         <CheckoutForm />
//                     </Elements>
//                 </div>
//             )}
//         </div>
//     );
// };

// export default PaymentForm;

import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  PaymentElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { useParams } from "react-router-dom";

const CheckoutForm = ({ placeOrder }) => {
  const total = JSON.parse(localStorage.getItem("order_details"));
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (elements == null) {
      return;
    }

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      // Show error to your customer
      setErrorMessage(submitError.message);
      return;
    }

    // Create the PaymentIntent and obtain clientSecret from your server endpoint
    const res = await fetch(
      "https://stripe-payment-server-seven.vercel.app/create-payment-intent",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          amount: total?.total,
          currency: "usd",
        }),
      }
    );

    const data = await res.json();
    console.log(data);
    const { error } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      clientSecret: data?.client_secret,
      confirmParams: {
        return_url: `${process.env.REACT_APP_URL}/finali`,
      },
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
    } else {
      placeOrder();
    }
  };

  const params = useParams();

  console.log(params);

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <button
        style={{
          backgroundColor: "#635BFF",
          border: "none",
          padding: "10px 70px",
          fontWeight: "500",
          color: "white",
          borderRadius: "10px",
          width: "100%",
          margin: "20px 0",
        }}
        type="submit"
        disabled={!stripe || !elements}
      >
        Pay
      </button>
      {/* Show error message to your customers */}
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  );
};

const stripePromise = loadStripe(
  "pk_test_51PDL8U2Kb7CbmLKZS0tlWVKXJyE3qQ0rFEelo2hQouuHdAn2ZLuHvdexeTFjOj4gjEr5Jqno7Kz54uMe8QadqWPx00LHWPYiMr"
);

const options = {
  mode: "payment",
  amount: 1099,
  currency: "usd",
  // Fully customizable with appearance API.
  appearance: {
    /*...*/
  },
};

const PaymentForm = ({ placeOrder }) => (
  <Elements stripe={stripePromise} options={options}>
    <CheckoutForm placeOrder={placeOrder} />
  </Elements>
);

export default PaymentForm;
