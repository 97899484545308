import React, { createContext, useState, useMemo } from 'react';

const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const [isAuth, setisAuth] = useState("");
  const [deliveryMethod, setDeliveryMethod] = useState(null);
  const contextValue = useMemo(() => ({
    isAuth,
    setisAuth,
    deliveryMethod,
    setDeliveryMethod
  }), [isAuth, deliveryMethod]);
  return (
    <GlobalContext.Provider value={contextValue}>
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContext;