import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import BannerNav from "../components/BannerNav";
import Visa from "../assets/visa.png";
import Mastercard from "../assets/Mastercard.png";
import Right from "../assets/circleright.png";
import Card from "../assets/locationSearch.svg";
import wallet from "../assets/wallet.svg";
import axios from "axios";
import { API_URL } from "../API/API_URL";
import { useNavigate } from "react-router-dom";
import { SnackbarProvider, enqueueSnackbar } from "notistack";
import { DotLoaderOverlay } from "react-spinner-overlay";

function Finali() {
  const [loading, setLoading] = useState(false);
  const smallText = {
    color: "#E52823",
    fontFamily: "var(--font-gotham)",
    fontSize: "35px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
    textAlign: "center",
    opacity: " 0.7",
    margin: "100px 0 0 0",
  };

  const cartItems = JSON.parse(localStorage.getItem("order_details"));
  console.log({ cartItems });
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  async function placeOrder() {
    setLoading(true);
    try {
      console.log("HERE I AM");
      const res = await axios.post(
        `${API_URL}/place-order`,
        {
          payment_type: cartItems?.payment_type,
          order_type: cartItems?.order_type,
          user_id: cartItems?.user_id,
          total: cartItems?.total,
          address_id: cartItems?.address_id,
          branch_address_id: cartItems?.branch_address_id,
          order_type: "cash",
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      if (res.status === 200) {
        setLoading(false);
        console.log("order ===========>", res);
        enqueueSnackbar("Order place successfully", {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
        setTimeout(() => {
          navigate(`/order-details/${res.data.id}`);
        }, 2000);
      }
    } catch (error) {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (cartItems) {
      placeOrder();
    }
  }, []);

  return (
    <>
      <DotLoaderOverlay loading={loading} color="red" />
      <SnackbarProvider />
      <div className="banner-bg"></div>
      <div className="d-flex flex-column align-items-center justify-content-center ">
        <img className="img-fluid" src={wallet} />
        <p style={smallText}>¡Se ha completado su pago!</p>
      </div>
    </>
  );
}

export default Finali;
