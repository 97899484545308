import React, { useContext, useState } from 'react'
import Radio from '@mui/material/Radio';
import Icon from "../assets/iicon.png"
import Timer from "../assets/Timer.png"
import Vector from "../assets/Vector.png"
import GlobalContext from '../context';

function MethodDelivery() {

    const { deliveryMethod, setDeliveryMethod } = useContext(GlobalContext)

    const methods = [
        { id: 1, name: "Delivery", image: "Icon" },
        { id: 2, name: "Para retirar", image: "Vector" },
        { id: 3, name: "Programar pedido", image: "Timer" },
    ]

    const textStyle1 = {
        color: '#323643',
        fontFamily: 'var(--font-gotham-black)',
        fontSize: '22px',
        fontStyle: 'normal',
        fontWeight: '900',
        lineHeight: 'normal',
        margin: "20px 0"
    }
    const textStyle12 = {
        color: ' var(--Basic_Red, #E52823)',
        fontFamily: 'var(--font-gotham)',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '900',
        lineHeight: '138.836 %',/* 22.214px */
        letterSpacing: '0.32px',
        margin: "0 0 0 10px",
        padding: 0,
        textAlign: "center"
    }

    console.log(deliveryMethod);

    return (
        <div>
            <div style={textStyle1}>
                Metodo de entrega
            </div>
            <div style={{ width: "400px" }}>
                {methods?.map((item, index) => {
                    return (
                        <>
                            <div key={index} className='d-flex justify-content-between align-items-center' style={{ margin: "10px 0" }}>
                                <div className='d-flex'>
                                    <div ><img src={item.image == "Icon" ? Icon : item.image == "Vector" ? Vector : Timer} className='img-fluid' style={{ width: "26px", height: "22px" }} /></div>
                                    <div style={{ height: "17px" }}>
                                        <div style={textStyle12}>{item.name}</div>
                                    </div>
                                </div>
                                <div>
                                    <Radio
                                        checked={item.id === deliveryMethod?.id}
                                        onChange={() => {
                                            setDeliveryMethod({ id: item.id, value: item.name, image: item.image == "Icon" ? Icon : item.image == "Vector" ? Vector : Timer })
                                        }}
                                        sx={{
                                            color: 'rgba(235, 37, 45, 1)',
                                            '&.Mui-checked': {
                                                color: 'rgba(235, 37, 45, 1)',
                                            },
                                        }}
                                    />
                                </div>

                            </div>
                            <div style={{
                                borderBottom: "1px solid grey"
                            }}>
                            </div>
                        </>

                    )
                })}




            </div>
            <br />
            <br />
        </div>
    )
}

export default MethodDelivery