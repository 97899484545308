import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import BannerNav from '../components/BannerNav'
import paymentCard from "../assets/payment-card.svg"
import Sidebar from '../components/Sidebar'
import settingIcon from '../assets/settings.svg';
import mapImg from '../assets/mapImg.png';
import { useNavigate } from 'react-router-dom'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import axios from 'axios'
import { API_URL } from '../API/API_URL'
import { DotLoaderOverlay } from 'react-spinner-overlay'
import { SnackbarProvider, enqueueSnackbar } from 'notistack'

function Ubicaciones2() {


    const [state, setState] = React.useState(false);
    const navigate = useNavigate()


    const [width, setWidth] = useState()

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth)
        };

        // Add event listener
        window.addEventListener("resize", handleResize);

        // Cleanup the event listener when the component unmounts
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []); // Empty dependency array means this effect will only run once (on mount)
    const [activeAddressType, setActiveAddressType] = useState({
        home1: false,
        home2: false,
        home3: false,
    })
    const inputStyle = {
        width: '350px',
        height: '57px',
        borderRadius: '100px',
        background: 'var(--Light_Blue, #F0F5FA)',
        border: "none",
        outline: "none",
        padding: "0 20px",
        color: ' #B5B5B5',
        fontFamily: 'var(--font-gotham)',
        fontSize: ' 14px',
        fontStyle: 'normal',
        fontWeight: '300',

        lineHeight: "normal"

    }
    const btnStyle = {
        width: '345px',
        height: '57px',
        borderRadius: '100px',
        background: '#EB252D',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: ' var(--white-100, #FFF)',
        fontFamily: 'var(--font-gotham)',
        fontSize: ' 16px',
        fontStyle: 'normal',
        fontWeight: '700',
        margin: "30px 0",
        cursor: "pointer"
        // marginBotton:"px"
    }
    const btnStyle2 = {
        width: '100px',
        height: '57px',
        borderRadius: '100px',
        background: activeAddressType.home1 && '#EB252D',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: activeAddressType.home1 ? 'var(--white-100, #FFF)' : '#ADADAF',
        fontFamily: 'var(--font-gotham)',
        fontSize: ' 16px',
        fontStyle: 'normal',
        fontWeight: '700',
        margin: width < 768 ? "30px 10px" : "30px 30px",
        cursor: "pointer"

        // marginBotton:"px"
    }
    const btnStyle3 = {
        width: '100px',
        height: '57px',
        borderRadius: '100px',
        background: activeAddressType.home2 && '#EB252D',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: activeAddressType.home2 ? 'var(--white-100, #FFF)' : '#ADADAF',
        fontFamily: 'var(--font-gotham)',
        fontSize: ' 16px',
        fontStyle: 'normal',
        fontWeight: '700',
        margin: width < 768 ? "30px 10px" : "30px 30px",
        cursor: "pointer"

        // marginBotton:"px"
    }
    const btnStyle4 = {
        width: '100px',
        height: '57px',
        borderRadius: '100px',
        background: activeAddressType.home3 && '#EB252D',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: activeAddressType.home3 ? 'var(--white-100, #FFF)' : '#ADADAF',
        fontFamily: 'var(--font-gotham)',
        fontSize: ' 16px',
        fontStyle: 'normal',
        fontWeight: '700',
        margin: width < 768 ? "30px 10px" : "30px 30px",
        cursor: "pointer"

        // marginBotton:"px"
    }

    const [selectedLocation, setSelectedLocation] = React.useState(null);
    const [selectedAddress, setSelectedAddress] = useState('');
    const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });
    const [mapZoom, setMapZoom] = useState(8);
    const [map, setMap] = useState(null);

    const token = localStorage.getItem("token")
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        // Set Santo Domingo coordinates as the initial map center
        setMapCenter({ lat: 18.4861, lng: -69.9312 });
        setMapZoom(13); // Set an appropriate zoom level
    }, []);


    const handleMapLoad = (map) => {
        setMap(map);
    };

    const handleMapClick = (event) => {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();

        setSelectedLocation({ lat, lng });
        setMapCenter({ lat, lng });

        // Perform reverse geocoding to get address
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ location: { lat, lng } }, (results, status) => {
            if (status === 'OK') {
                if (results[0]) {
                    const addressComponents = results[0].address_components;
                    // Extract address components
                    const street = addressComponents.find(component => component.types.includes('street_number'))?.long_name || '';
                    const additional = addressComponents.find(component => component.types.includes('sublocality'))?.long_name || '';
                    const type = addressComponents.find(component => component.types.includes('establishment'))?.long_name || '';

                    setAddress({ direction: results[0].formatted_address, street, additional, type });
                    setSelectedAddress(results[0].formatted_address);
                    const placeId = results[0].place_id;
                    console.log("Place ID:", placeId);
                } else {
                    setAddress({
                        direction: "",
                        street: "",
                        additional: "",
                        type: ""
                    });
                    setSelectedAddress('Address not found');
                }
            } else {
                setAddress({
                    direction: "",
                    street: "",
                    additional: "",
                    type: ""
                });
                setSelectedAddress('Geocoder failed due to: ' + status);
            }
        });
    };

    const [address, setAddress] = useState({
        direction: "",
        street: "",
        additional: "",
        type: ""
    })

    // console.log(address);

    return (
        <>
            {/* <Navbar /> */}
            <SnackbarProvider />
            <DotLoaderOverlay color="red" loading={loading} />
            <div
                style={{
                    position: "relative",
                    cursor: "pointer"
                }}
            >
                <div
                    onClick={() => setState(true)}
                    style={{
                        position: "absolute",
                        backgroundColor: "red",
                        borderRadius: "100%",
                        width: "60px",
                        height: "60px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        top: 100,
                        right: 20,
                        zIndex: 1000
                    }}>
                    <img src={settingIcon} />
                </div>
            </div>
            <BannerNav text={'Ubicaciones guardadas'} icon={paymentCard} />
            <br />
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                {/* <div style={{ marginBottom: 40 }}> */}
                {/* <img style={{width: width < 768 ? "250px" : "100%"}} src={mapImg} alt="" /> */}
                <LoadScript googleMapsApiKey="AIzaSyABy0de4oAU34qkNVvF4xiiVmvS9zdeiMY">
                    <GoogleMap
                        mapContainerStyle={{ width: '50%', height: '400px' }}
                        zoom={mapZoom}
                        center={mapCenter}
                        onLoad={handleMapLoad}
                        onClick={handleMapClick}
                    >
                        {selectedLocation && (
                            <Marker position={selectedLocation} />
                        )}
                    </GoogleMap>
                </LoadScript>
                {/* </div> */}
                <div style={{ marginTop: "50px" }}>
                    <div style={{ position: "relative", margin: "8px 0" }} >
                        <div>
                            <input value={address.direction}
                                onChange={(e) => {
                                    setAddress((prevFields) => {
                                        return {
                                            ...prevFields,
                                            direction: e.target.value,
                                        }
                                    })
                                }}
                                type='text' style={inputStyle} placeholder='Direccion*' />
                        </div>
                    </div>
                </div>
                <div>
                    <div style={{ position: "relative", margin: "8px 0" }} >
                        <div>
                            <input value={address.street}
                                onChange={(e) => {
                                    setAddress((prevFields) => {
                                        return {
                                            ...prevFields,
                                            street: e.target.value,
                                        }
                                    })
                                }}
                                type='text' style={inputStyle} placeholder='Calle*' />
                        </div>
                    </div>
                </div>
                <div>
                    <div style={{ position: "relative", margin: "8px 0" }}>
                        <div>
                            <input value={address.additional}
                                onChange={(e) => {
                                    setAddress((prevFields) => {
                                        return {
                                            ...prevFields,
                                            additional: e.target.value,
                                        }
                                    })
                                }}
                                type='text' style={inputStyle} placeholder='Adicional' />
                        </div>
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                    <div onClick={() => {
                        setActiveAddressType({ home1: true, home2: false, home3: false })
                        setAddress({ ...address, type: "casa" })
                    }} style={btnStyle2}>Casa</div>
                    <div onClick={() => {
                        setActiveAddressType({ home1: false, home2: true, home3: false })
                        setAddress({ ...address, type: "casa" })
                    }} style={btnStyle3}>Casa</div>
                    <div onClick={() => {
                        setActiveAddressType({ home1: false, home2: false, home3: true })
                        setAddress({ ...address, type: "casa" })
                    }} style={btnStyle4}>Casa</div>
                </div>

                <div style={btnStyle} onClick={async () => {
                    setLoading(true)
                    try {
                        const res = await axios.post(`${API_URL}/address`, {
                            ...address
                        }, {
                            headers: {
                                Authorization: "Bearer " + token
                            }
                        })
                        if (res.status === 201) {
                            console.log(res);
                            setLoading(false)
                            enqueueSnackbar("Location added successfully", {
                                variant: "success",
                                anchorOrigin: {
                                    vertical: 'top',
                                    horizontal: 'right'
                                }
                            })
                            setTimeout(() => {
                                navigate("/Ubicaciones1")
                            }, 2000);
                        }
                    } catch (error) {
                        setLoading(false)
                        console.log(error);
                    }
                }}>Agregar Método de Pago</div>

            </div>

            <Sidebar state={state} setState={setState} />
        </>
    )
}

export default Ubicaciones2