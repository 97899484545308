import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme, } from '@mui/material/styles';
import Cross from "../assets/cut.png"
import courierBoyProfile from "../assets/courier-boy-profile.svg"
import sendIcon from "../assets/sendIcon.svg"
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import BasicTimePicker from './TimePIcker';

function ChatModal({ open, setOpen }) {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [value, onChange] = React.useState(new Date());

    const handleClose = () => {
        setOpen(false);
    };

    const textStyle = {
        backgroundColor: "#EB001B",
        width: "142px",
        height: "51px",
        borderRadius: "50px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    }

    const textStyle2 = {
        backgroundColor: "#F0F5FA",
        borderRadius: "50px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "15px 10px"
    }

    console.log(value);

    return (
        <React.Fragment>
            <Dialog
                id='chat-modal'
                // fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                PaperProps={{
                    style: {
                        borderRadius: "28px",
                        background: 'var(--white-100, #FFF)',
                        boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.25)',
                        overflowY: "none !important"
                    },
                }}
            >
                <DialogTitle id="responsive-dialog-title" style={{ padding: "0px", position: 'relative' }}>
                    <div>
                        <div className='chat-bg'>
                            <p style={{
                                fontSize: "18px",
                                fontWeight: "900",
                                fontFamily: 'var(--font-gotham)',
                                padding: "44px 0 0 62px"
                            }}>Juan Miguel</p>
                        </div>
                        <div style={{ position: 'absolute', top: "20px", right: "20px" }}>
                            <img className='img-fluid' style={{ cursor: "pointer" }} onClick={handleClose} width={27} height={27} src={Cross} alt="cross" />
                        </div>
                    </div>
                    <div style={{ padding: "0 60px", height: "450px", overflowY: "scroll" }}>
                        <div style={{
                            display: "flex",
                            justifyContent: "right",
                            flexDirection: "column",
                            alignItems: "end"
                        }}>
                            <p style={{
                                fontSize: "12px", fontWeight: "900", color: "#ABABAB", margin: 0, fontFamily: 'var(--font-gotham)',
                            }}>8:10 pm</p>
                            <div style={textStyle}>
                                <p style={{
                                    fontSize: "14px",
                                    fontFamily: 'var(--font-gotham)',
                                    fontWeight: "900",
                                    color: "white",
                                    margin: 0
                                }}>Ya va de camino?</p>
                            </div>
                        </div>
                        <div style={{
                            display: "flex",
                            justifyContent: "right",
                            flexDirection: "column",
                            alignItems: "start"
                        }}>
                            <p style={{
                                fontSize: "12px", fontWeight: "900", color: "#ABABAB", margin: 0, fontFamily: 'var(--font-gotham)',
                            }}>8:10 pm</p>
                            <div style={{ display: "flex" }}>
                                <img style={{ margin: "0 10px 0 0" }} src={courierBoyProfile} alt="" />
                                <div style={textStyle2}>
                                    <p style={{
                                        fontSize: "14px",
                                        fontFamily: 'var(--font-gotham)',
                                        fontWeight: "900",
                                        color: "#32343E",
                                        margin: 0
                                    }}>Si, le va llegar una notificacion</p>
                                </div>
                            </div>
                        </div>
                        <div style={{
                            display: "flex",
                            justifyContent: "right",
                            flexDirection: "column",
                            alignItems: "end"
                        }}>
                            <p style={{
                                fontSize: "12px", fontWeight: "900", color: "#ABABAB", margin: 0, fontFamily: 'var(--font-gotham)',
                            }}>8:10 pm</p>
                            <div style={textStyle}>
                                <p style={{
                                    fontSize: "14px",
                                    fontFamily: 'var(--font-gotham)',
                                    fontWeight: "900",
                                    color: "white",
                                    margin: 0
                                }}>Ya va de camino?</p>
                            </div>
                        </div>
                        <div style={{
                            display: "flex",
                            justifyContent: "right",
                            flexDirection: "column",
                            alignItems: "start"
                        }}>
                            <p style={{
                                fontSize: "12px", fontWeight: "900", color: "#ABABAB", margin: 0, fontFamily: 'var(--font-gotham)',
                            }}>8:10 pm</p>
                            <div style={{ display: "flex" }}>
                                <img style={{ margin: "0 10px 0 0" }} src={courierBoyProfile} alt="" />
                                <div style={textStyle2}>
                                    <p style={{
                                        fontSize: "14px",
                                        fontFamily: 'var(--font-gotham)',
                                        fontWeight: "900",
                                        color: "#32343E",
                                        margin: 0
                                    }}>Si, le va llegar una notificacion</p>
                                </div>
                            </div>
                        </div>
                        <div style={{
                            display: "flex",
                            justifyContent: "right",
                            flexDirection: "column",
                            alignItems: "end"
                        }}>
                            <p style={{
                                fontSize: "12px", fontWeight: "900", color: "#ABABAB", margin: 0, fontFamily: 'var(--font-gotham)',
                            }}>8:10 pm</p>
                            <div style={textStyle}>
                                <p style={{
                                    fontSize: "14px",
                                    fontFamily: 'var(--font-gotham)',
                                    fontWeight: "900",
                                    color: "white",
                                    margin: 0
                                }}>Ya va de camino?</p>
                            </div>
                        </div>
                        <div style={{
                            display: "flex",
                            justifyContent: "right",
                            flexDirection: "column",
                            alignItems: "start"
                        }}>
                            <p style={{
                                fontSize: "12px", fontWeight: "900", color: "#ABABAB", margin: 0, fontFamily: 'var(--font-gotham)',
                            }}>8:10 pm</p>
                            <div style={{ display: "flex" }}>
                                <img style={{ margin: "0 10px 0 0" }} src={courierBoyProfile} alt="" />
                                <div style={textStyle2}>
                                    <p style={{
                                        fontSize: "14px",
                                        fontFamily: 'var(--font-gotham)',
                                        fontWeight: "900",
                                        color: "#32343E",
                                        margin: 0
                                    }}>Si, le va llegar una notificacion</p>
                                </div>
                            </div>
                        </div>
                        <div style={{
                            display: "flex",
                            justifyContent: "right",
                            flexDirection: "column",
                            alignItems: "end"
                        }}>
                            <p style={{
                                fontSize: "12px", fontWeight: "900", color: "#ABABAB", margin: 0, fontFamily: 'var(--font-gotham)',
                            }}>8:10 pm</p>
                            <div style={textStyle}>
                                <p style={{
                                    fontSize: "14px",
                                    fontFamily: 'var(--font-gotham)',
                                    fontWeight: "900",
                                    color: "white",
                                    margin: 0
                                }}>Ya va de camino?</p>
                            </div>
                        </div>
                        <div style={{
                            display: "flex",
                            justifyContent: "right",
                            flexDirection: "column",
                            alignItems: "start"
                        }}>
                            <p style={{
                                fontSize: "12px", fontWeight: "900", color: "#ABABAB", margin: 0, fontFamily: 'var(--font-gotham)',
                            }}>8:10 pm</p>
                            <div style={{ display: "flex" }}>
                                <img style={{ margin: "0 10px 0 0" }} src={courierBoyProfile} alt="" />
                                <div style={textStyle2}>
                                    <p style={{
                                        fontSize: "14px",
                                        fontFamily: 'var(--font-gotham)',
                                        fontWeight: "900",
                                        color: "#32343E",
                                        margin: 0
                                    }}>Si, le va llegar una notificacion</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ padding: "0 60px", position: "relative" }}>
                        <img style={{ position: "absolute", right: 90, top: 20 }} src={sendIcon} />
                        <input placeholder='Escribe algo' style={{
                            width: "100%",
                            padding: "20px",
                            backgroundColor: "#F0F5FA",
                            border: "1px solid red",
                            color: "#ADADAF",
                            fontSize: "14px",
                            fontwe: "900",
                            fontFamily: 'var(--font-gotham)',
                            borderRadius: "30px"
                        }} />
                    </div>
                </DialogTitle>
                {/* <DialogTitle id="responsive-dialog-title" style={{ padding: "0", position: 'relative' }}>
                    <div>
                        <div className='chat-bg'>
                            <p style={{
                                fontSize: "22px",
                                fontWeight: "900",
                                fontFamily: 'var(--font-gotham)',
                                padding: "44px 0 0 62px",
                                color: "#EB252D"
                            }}>Programar pedido</p>
                        </div>
                        <div style={{ position: 'absolute', top: "20px", right: "20px" }}>
                            <img className='img-fluid' style={{ cursor: "pointer" }} onClick={handleClose} width={27} height={27} src={Cross} alt="cross" />
                        </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Calendar onChange={onChange} value={value} />
                    </div>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "50px 0 0 0" }}>
                        <BasicTimePicker />
                    </div>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "50px 0 0 0" }}>
                        <button style={{
                            width: "353px",
                            height: "59px",
                            backgroundColor: "#EB252D",
                            border: "none",
                            fontWeight: "900",
                            fontFamily: 'var(--font-gotham)',
                            color: "#FFF",
                            borderRadius:"30px"
                        }}>Aceptar</button>
                    </div>
                </DialogTitle> */}
                <br />
            </Dialog>
        </React.Fragment>
    );
}

export default ChatModal;