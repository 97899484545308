import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import BannerNav from '../components/BannerNav'
import TotalBox from '../components/TotalBox'
import MethodDelivery from '../components/MethodDelivery'
import ProductImage from "../assets/zinger.png"

import Star from "../assets/star.png"
import Footer from '../components/Footer'
import CustBox from '../components/CustBox'
import backIcon from "../assets/chevron.png";
import deleteIcon from "../assets/Delete.svg";
import statusCheck from "../assets/status-complete.svg";
import location2 from "../assets/location2.svg";
import mapRoute from "../assets/map-route.svg";
import mapIndicator from "../assets/map-indicator.svg";
import send from "../assets/send.svg";
import ChatModal from '../components/ChatModal'
import { useNavigate, useParams } from 'react-router-dom'
import OrderTracker from '../components/RiderLiveLocation'
import axios from 'axios'
import { API_URL } from '../API/API_URL'
import { DotLoaderOverlay } from 'react-spinner-overlay'
import RatingModal from '../components/RatingModal'

function OrderDetails() {

    const [open, setOpen] = useState(false);
    const [trackOrder, setTrackOrder] = useState(null);
    const [riderId, setRiderId] = useState(null);
    const [width, setWidth] = useState(window.innerWidth);
    const [loading, setLoading] = useState(false);
    const params = useParams();
    const navigate = useNavigate();
    const token = localStorage.getItem("token");

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    async function trackMyOrder() {
        try {
            const response = await axios.get(`${API_URL}/orders-in-progress`, {
                headers: {
                    Authorization: "Bearer " + token
                }
            });
            if (response.status === 200) {
                const foundOrder = response.data.find(order => Number(params.id) === order.o_id);
                if (foundOrder) {
                    setTrackOrder(foundOrder);
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    async function trackStatus() {
        try {
            const response = await axios.get(`${API_URL}/my-orders`, {
                headers: {
                    Authorization: "Bearer " + token
                }
            });
            if (response.status === 200) {
                const foundOrder = response.data.find(order => Number(params.id) === order.id);
                if (foundOrder) {
                    setRiderId(foundOrder.driver_id);
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        const interval = setInterval(() => {
            trackMyOrder();
        }, 5000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (trackOrder?.status === "out-for-delivery") {
            trackStatus();
        }
    }, [trackOrder]);

    const headText = {
        color: '#EB252D',
        fontFamily: 'var(--font-gotham-black)',
        fontSize: ' 18px',
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: 'normal',
        margin: "10px"
    }
    const smallText = {
        color: ' var(--Medium_grey, #646982)',
        fontFamily: 'var(--font-gotham)',
        fontSize: '15px',
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: '30px',
        textTransform: 'capitalize',
        margin: "10px"

    }
    const smallText2 = {
        color: ' var(--Medium_grey, #E52823)',
        fontFamily: 'var(--font-gotham)',
        fontSize: '15px',
        fontStyle: 'normal',
        fontWeight: '700',
        textTransform: 'capitalize',
        margin: "50px 0 0 0",
        cursor: "pointer"
    }
    const date = {
        color: ' var(--Medium_grey, #646982)',
        fontFamily: 'var(--font-gotham)',
        fontSize: '15px',
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: 'normal',
        textTransform: 'capitalize',
        margin: "10px"
    }

    return (
        <>
            {trackOrder?.status === "delivered" && <RatingModal open={true} setOpen={setOpen} trackOrder={trackOrder} />}
            <DotLoaderOverlay loading={loading} color="red" />
            <ChatModal open={open} setOpen={setOpen} />
            <BannerNav text="Detalles del envío" icon={backIcon} onClick={() => navigate(-1)} />
            <div className='container'>
                <div>
                    <div style={{ display: "flex" }}>
                        <div style={{
                            width: '228px',
                            height: '136px'
                        }}>
                            <img className='img-fluid' src={ProductImage} />
                        </div>
                        <div>
                            <div style={{ ...headText, fontSize: 30 }}>{trackOrder?.order_id}</div>
                            <div style={date}>{trackOrder?.created_at}</div>
                            <div style={headText}>{trackOrder?.items[trackOrder?.items?.length - 1]?.product_name}</div>
                            <div style={{ margin: "10px" }} className='d-flex  align-items-center'>
                                <div><img src={Star} /></div>
                                <div className='mt-1' style={{ color: "#A5A5A5", fontSize: "14px", fontFamily: "var(--font-gotham)" }}>4.5</div>
                            </div>
                            <div style={smallText}>1x Refresco de uva</div>
                            <div style={smallText}>1x Papa mediana</div>
                            <div onClick={() => navigate(`/order-cancelation/${params.id}`)} style={smallText2}> <img src={deleteIcon} alt="" /> Cancelar pedido</div>
                        </div>
                    </div>
                    <div style={{ marginTop: "100px" }}>
                        <div style={{ display: "flex" }}>
                            <img src={location2} />
                            <p style={{ padding: 0, margin: "0 0 0 10px" }}>Avn. John F. Kennedy esquina Tiradentes, estación de combustible Sunix</p>
                        </div>
                        <div style={{
                            display: "flex",
                            alignItems: width < 768 ? "start" : "center",
                            flexDirection: width < 768 ? "column" : "row",
                        }}>
                            <div style={{
                                width: width < 768 ? "100%" : "710px",
                                height: "397px",
                                background: "var(--Light_Grey, #D0D9E1)",
                                position: "relative"
                            }}>
                                <OrderTracker riderId={riderId} trackOrder={trackOrder} />
                            </div>
                            <div style={{ marginLeft: "10px", position: "relative" }}>
                                <div>
                                    <p style={{
                                        color: "#181C2E",
                                        fontFamily: 'var(--font-gotham)',
                                        fontSize: '30px',
                                        fontStyle: "normal",
                                        fontWeight: "700",
                                        lineHeight: "normal",
                                        margin: 0
                                    }}>20 min</p>
                                    <p style={{
                                        color: "#ADADAF",
                                        fontFamily: 'var(--font-gotham)',
                                        fontSize: '14px',
                                        fontStyle: "normal",
                                        fontWeight: "400",
                                        lineHeight: "normal",
                                    }}>Tiempo estimado de entrega <br /> 10:20 pm</p>
                                </div>
                                <div>

                                    <div style={{ display: "flex", margin: "30px 0" }}>
                                        {/* <img src={statusCheck} /> */}
                                        <div style={{ width: 23, height: 23, backgroundColor: trackOrder?.status === "in-progress" ? "#EB252D" : "#ADADAF", borderRadius: "100%" }}></div>
                                        <p style={{
                                            fontSize: "15px",
                                            color: trackOrder?.status === "in-progress" ? "#EB252D" : "#ADADAF",
                                            padding: 0,
                                            margin: "0 0 0 10px"
                                        }}>Tu orden ha sido recibidaen el restaurante</p>
                                    </div>
                                    <div style={{
                                        width: "3px",
                                        height: "38px",
                                        backgroundColor: trackOrder?.status === "in-progress" ? "#EB252D" : "#ADADAF",
                                        position: "absolute",
                                        top: 120,
                                        left: 10,
                                        zIndex: -1000
                                    }}></div>

                                    <div style={{ display: "flex", margin: "30px 0" }}>
                                        {/* <img src={statusCheck} /> */}
                                        <div style={{ width: 23, height: 23, backgroundColor: trackOrder?.status === "preparing" ? "#EB252D" : "#ADADAF", borderRadius: "100%" }}></div>
                                        <p style={{
                                            fontSize: "15px",
                                            color: trackOrder?.status === "preparing" ? "#EB252D" : "#ADADAF",
                                            padding: 0,
                                            margin: "0 0 0 10px"
                                        }}>El restaurante preparatu orden</p>
                                    </div>
                                    <div style={{
                                        width: "3px",
                                        height: "38px",
                                        backgroundColor: trackOrder?.status === "preparing" ? "#EB252D" : "#ADADAF",
                                        position: "absolute",
                                        top: 170,
                                        left: 10,
                                        zIndex: -1000
                                    }}></div>

                                    <div style={{ display: "flex", margin: "30px 0" }}>
                                        {/* <img src={statusCheck} /> */}
                                        <div style={{ width: 23, height: 23, backgroundColor: trackOrder?.status === "out-for-delivery" ? "#EB252D" : "#ADADAF", borderRadius: "100%" }}></div>
                                        <p style={{
                                            fontSize: "15px",
                                            color: trackOrder?.status === "out-for-delivery" ? "#EB252D" : "#ADADAF",
                                            padding: 0,
                                            margin: "0 0 0 10px"
                                        }}>Tu orden se ha enviado</p>
                                    </div>
                                    <div style={{
                                        width: "3px",
                                        height: "38px",
                                        backgroundColor: trackOrder?.status === "out-for-delivery" ? "#EB252D" : "#ADADAF",
                                        position: "absolute",
                                        top: 220,
                                        left: 10,
                                        zIndex: -1000
                                    }}></div>

                                    <div style={{ display: "flex", margin: "30px 0" }}>
                                        {/* <img src={statusCheck} /> */}
                                        <div style={{ width: 23, height: 23, backgroundColor: trackOrder?.status === "delivered" ? "#EB252D" : "#ADADAF", borderRadius: "100%" }}></div>
                                        <p style={{
                                            fontSize: "15px",
                                            color: trackOrder?.status === "delivered" ? "#EB252D" : "#ADADAF",
                                            padding: 0,
                                            margin: "0 0 0 10px"
                                        }}>Tu pedido ha llegado!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: "flex", alignItems: "center", marginTop: "70px" }}>
                            <div style={{
                                width: "110px",
                                height: "110px",
                                borderRadius: "100%",
                                backgroundColor: "#D0D9E1"
                            }}></div>
                            <div style={{ display: "flex" }}>
                                <div style={{ marginLeft: "20px" }}>
                                    <p style={{
                                        color: "#181C2E",
                                        fontSize: "20px",
                                        fontStyle: "normal",
                                        fontWeight: "700",
                                        textTransform: "capitalize",
                                        fontFamily: 'var(--font-gotham)',
                                        margin: 0
                                    }}>Servicio al cliente</p>
                                    <p style={{
                                        color: "#A0A5BA",
                                        fontSize: "14px",
                                        fontStyle: "normal",
                                        fontWeight: "700",
                                        textTransform: "capitalize",
                                        fontFamily: 'var(--font-gotham)',
                                        margin: 0
                                    }}>Delivery</p>
                                    <p style={{
                                        color: "#A0A5BA",
                                        fontSize: "14px",
                                        fontStyle: "normal",
                                        fontWeight: "700",
                                        textTransform: "capitalize",
                                        fontFamily: 'var(--font-gotham)',
                                        margin: "10px 0 0 0"
                                    }}>000 - 000 - 0000</p>
                                </div>
                                <div style={{ marginLeft: 20 }}>
                                    <div onClick={() => setOpen(true)} style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        width: "45px",
                                        height: "45px",
                                        backgroundColor: "#EB252D",
                                        borderRadius: "100%",
                                        cursor: "pointer"
                                    }}>
                                        <img src={send} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OrderDetails