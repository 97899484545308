import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import BannerNav from '../components/BannerNav'
import Email from "../assets/Email.png"
import Edit from "../assets/pencil.png"
import Eye from "../assets/eye.png"
import userIcon from "../assets/user-circle.svg"

import Sidebar from '../components/Sidebar'
import settingIcon from '../assets/settings.svg';
import axios from 'axios'
import { API_URL } from '../API/API_URL'
import { DotLoaderOverlay } from 'react-spinner-overlay'
import { SnackbarProvider, enqueueSnackbar } from 'notistack'

function Personalize() {


    const [state, setState] = React.useState(false);
    const [showPassword, setShowPassword] = React.useState(false);

    const inputStyle = {
        width: '398px',
        height: '58px',
        borderRadius: '100px',
        background: 'var(--Light_Blue, #F0F5FA)',
        border: "none",
        outline: "none",
        padding: "0 60px",
        color: ' #B5B5B5',
        fontFamily: 'var(--font-gotham)',
        fontSize: ' 14px',
        fontStyle: 'normal',
        fontWeight: '900',
        lineHeight: "normal"
    }

    const inputStyle1 = {
        width: '398px',
        height: '58px',
        borderRadius: '100px',
        background: 'var(--Light_Blue, #F0F5FA)',
        border: "none",
        outline: "none",
        padding: "0 22px",
        color: ' #B5B5B5',
        fontFamily: 'var(--font-gotham)',
        fontSize: ' 14px',
        fontStyle: 'normal',
        fontWeight: '900',

        lineHeight: "normal"

    }
    const btnStyle = {
        width: "398px",
        backgroundColor: 'var(--basic-red)',
        // padding: "19px",
        height: "53px",
        borderRadius: "100px",
        // textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#fff",
        cursor: "pointer",
        margin: "15px"
    }

    const token = localStorage.getItem("token")
    const [profile, setProfile] = useState({
        id: "",
        name: "",
        email: "",
        telephone: "",
        password: "",
        password_confirmation: "",
    })
    const [loading, setLoading] = useState(false)

    const getMyProfile = async () => {
        setLoading(true)
        try {
            const response = await axios.get(`${API_URL}/me`, {
                headers: {
                    Authorization: "Bearer " + token
                }
            })
            if (response.status === 200) {
                console.log(response);
                setProfile({
                    id: response?.data?.user?.id,
                    name: response?.data?.user?.name,
                    email: response?.data?.user?.email,
                    telephone: response?.data?.user?.telephone,
                    password: "",
                    password_confirmation: "",
                })
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            console.log(error);
        }
    }

    const updateMyProfile = async () => {
        setLoading(true)
        try {
            const response = await axios.post(`${API_URL}/update-profile/${profile.id}`, {
                ...profile,
                name: profile?.name,
                email: profile?.email,
                password: profile?.password,
                password_confirmation: profile?.password_confirmation,
            }, {
                headers: {
                    Authorization: "Bearer " + token
                }
            })
            if (response.status === 200) {
                console.log(response);
                enqueueSnackbar("Profile updated successfully", {
                    variant: "success",
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }
                })
                localStorage.setItem("currentUser", JSON.stringify(response.data))
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            console.log(error);
        }
    }

    useEffect(() => {
        getMyProfile()
    }, [])

    console.log(profile);

    return (
        <>
            <SnackbarProvider />
            <DotLoaderOverlay loading={loading} color="red" />
            <div
                style={{
                    position: "relative",
                    cursor: "pointer"
                }}
            >
                <div
                    onClick={() => setState(true)}
                    style={{
                        position: "absolute",
                        backgroundColor: "red",
                        borderRadius: "100%",
                        width: "60px",
                        height: "60px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        top: 100,
                        right: 20,
                        zIndex: 1000
                    }}>
                    <img src={settingIcon} />
                </div>
            </div>
            <BannerNav text={'Datos personales'} icon={userIcon} />
            <div className='d-flex justify-content-center'>
                <div style={{ position: "relative", margin: "10px 0" }} >
                    <input onChange={(e) => setProfile({ ...profile, name: e.target.value })} value={profile?.name} type='text' placeholder='Nombre*' style={inputStyle} />
                    <div style={{ position: "absolute", top: "17px", left: "17px" }}><img width={24} height={24} src={Edit} /></div>
                </div>
            </div>
            <div className='d-flex justify-content-center'>
                <div style={{ position: "relative", margin: "10px 0" }} >
                    <input onChange={(e) => setProfile({ ...profile, email: e.target.value })} value={profile?.email} type='text' placeholder='Apellido*' style={inputStyle} />
                    <div style={{ position: "absolute", top: "17px", left: "17px" }}><img width={24} height={24} src={Edit} /></div>
                </div>
            </div>
            <br />
            <br />
            <div className='d-flex justify-content-center flex-column align-items-center'>
                <div style={{ position: "relative", margin: "10px 0" }} >
                    <div >
                        <input onChange={(e) => setProfile({ ...profile, password: e.target.value })} value={profile?.password} type={showPassword === true ? "text" : "password"} placeholder='Contraseña*' style={inputStyle1} />
                    </div>
                    <div style={{ position: "absolute", top: "17px", right: "25px" }} onClick={() => setShowPassword(!showPassword)}><img width={24} height={24} src={Eye} /></div>
                </div>
                <div style={{ position: "relative", margin: "10px 0" }} >
                    <div >
                        <input onChange={(e) => setProfile({ ...profile, password_confirmation: e.target.value })} value={profile?.password_confirmation} type={showPassword === true ? "text" : "password"} placeholder='Repetir contraseña*' style={inputStyle1} />
                    </div>
                    <div style={{ position: "absolute", top: "17px", right: "25px" }} onClick={() => setShowPassword(!showPassword)}><img width={24} height={24} src={Eye} /></div>
                </div>
                <div onClick={updateMyProfile} style={btnStyle}>Registrarse</div>
            </div>
            <Sidebar state={state} setState={setState} />
        </>
    )
}

export default Personalize