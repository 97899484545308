import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import BackgroundImage from '../assets/5.png'
import GlobalContext from '../context'
import Star from '../assets/star.png';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';

function Products({ items, likeRelatedProduct, setLoading, getProductByCategory }) {

    const myStyle = {
        backgroundImage: `url('${BackgroundImage}')`,
        width: '98vw',
        height: '100vh', // Ensure this is set to 100%
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    };

    const token = localStorage.getItem("token")
    const { isAuth, setisAuth } = useContext(GlobalContext)
    const navigate = useNavigate();

    return (
        <>
            <div className='container-fluid img-fluid' style={myStyle}>
                <div className='container ml-5' style={{ height: 'auto' }}>
                    <div className='row'>
                        {items?.map((item, index) => (
                            <div key={index} className='col-lg-4 col-md-6 my-5' style={{ cursor: 'pointer' }}>
                                <div className="card position-relative test">
                                    <div className="card-body">
                                        <div style={{ display: "flex", justifyContent: "flex-end" }} onClick={() => {
                                            if (token === null) {
                                                setisAuth("NOT AUTHENTICATED")
                                            }
                                            else {
                                                setisAuth("AUTHENTICATED")
                                                likeRelatedProduct(item)
                                            }
                                        }}>
                                            {item?.like === 0 ? <FavoriteBorderIcon style={{ fontSize: "30px", cursor: "pointer", color: "red" }} /> : <FavoriteIcon style={{ fontSize: "30px", cursor: "pointer", color: "red" }} />}
                                        </div>
                                        <div onClick={() => navigate(`/third/${item.id}`)}>
                                            <img src={item?.image} className='img-fluid' alt='' />
                                            <p className="card-text textshort">{item?.name}</p>
                                            <div>
                                                <p className='card-text check'>
                                                    {item?.description.slice(0,150)}...
                                                </p>
                                            </div>
                                            <div className='mt-5'>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <img src={Star} />
                                                    <span style={{ marginLeft: 10 }} className='rating'>{item?.avg_rating}</span>
                                                </div>
                                                <div style={{ borderTopLeftRadius: "20px", width: "200px", textAlign: "center" }} className="position-absolute bottom-0 end-0 top-20 bg-danger p-3 border-top-left-radius-5">
                                                    <span className='number text-white text-center'>$ 345.00</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Products;