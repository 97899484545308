import React from 'react'
import { BrowserRouter as Router, Routes, Route, useLocation, Navigate } from 'react-router-dom'
import Root from './root'
import './index.css'
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Home from './pages/Home.jsx';
import Second from './pages/Second.jsx';
import Third from './pages/Third.jsx';
import Fourth from './pages/Fourth.jsx';
import Fifth from './pages/Fifth.jsx';
import Modal from './components/Modal.jsx';
import LoginModal from './components/LoginModal.jsx';
import Signup from './components/Signup.jsx';
import Forget from './components/Forget.jsx';
import Personalize from './pages/Personalize.jsx';
import Pedidos from './pages/Pedidos.jsx';
import Metados from './pages/Metados.jsx';
import Metados1 from './pages/Metados1.jsx';
import Metados2 from './pages/Metados2.jsx';
import Ubicaciones from './pages/Ubicaciones.jsx';
import Ubicaciones1 from './pages/Ubicaciones1.jsx';
import Checking from './pages/Checking.jsx';
import Finali from './pages/Finali.jsx';
import Cancelation from './pages/Cancelation.jsx';
import OrderDetails from './pages/orderDetails.jsx';
import OrderCancelation from './pages/orderCancelation.jsx';
import Ubicaciones2 from './pages/Ubicaciones2.jsx';

const RouterApp = () => {

  const token = localStorage.getItem("token")
  console.log(localStorage.getItem("token"));
  console.log(JSON.parse(localStorage.getItem("currentUser")));

  return (
    <Router>
      <Routes>
        <Route path='/' element={<Root />}>
          <Route path="/" element={<Home />} />
          <Route path="/second" element={token !== null ? <Second /> : <Navigate to="/" />} />
          <Route path="/third/:id" element={token !== null ? <Third /> : <Navigate to="/" />} />
          <Route path="/fourth" element={token !== null ? <Fourth /> : <Navigate to="/" />} />
          <Route path="/fifth" element={token !== null ? <Fifth /> : <Navigate to="/" />} />
          <Route path="/pedidos" element={token !== null ? <Pedidos /> : <Navigate to="/" />} />
          <Route path="/Metados" element={token !== null ? <Metados /> : <Navigate to="/" />} />
          <Route path="/Metados1" element={token !== null ? <Metados1 /> : <Navigate to="/" />} />
          <Route path="/Metados2" element={token !== null ? <Metados2 /> : <Navigate to="/" />} />
          <Route path="/Ubicaciones" element={token !== null ? <Ubicaciones /> : <Navigate to="/" />} />
          <Route path="/Ubicaciones1" element={token !== null ? <Ubicaciones1 /> : <Navigate to="/" />} />
          <Route path="/finali" element={token !== null ? <Finali /> : <Navigate to="/" />} />
          <Route path="/cancelation" element={token !== null ? <Cancelation /> : <Navigate to="/" />} />
          <Route path="/order-details/:id" element={token !== null ? <OrderDetails /> : <Navigate to="/" />} />
          <Route path="/order-cancelation/:id" element={token !== null ? <OrderCancelation /> : <Navigate to="/" />} />
          <Route path="/checking" element={token !== null ? <Checking /> : <Navigate to="/" />} />
          <Route path="/personalize" element={token !== null ? <Personalize /> : <Navigate to="/" />} />
          <Route path="/Ubicaciones2" element={token !== null ? <Ubicaciones2 /> : <Navigate to="/" />} />
        </Route>
      </Routes>
    </Router>
  )
}

export default RouterApp