import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import BannerNav from '../components/BannerNav'
import TotalBox from '../components/TotalBox'
import MethodDelivery from '../components/MethodDelivery'
import ProductImage from "../assets/zinger.png"

import Star from "../assets/star.png"
import Footer from '../components/Footer'
import CustBox from '../components/CustBox'
import backIcon from "../assets/chevron.png";
import deleteIcon from "../assets/Delete.svg";
import statusCheck from "../assets/status-complete.svg";
import location2 from "../assets/location2.svg";
import mapRoute from "../assets/map-route.svg";
import mapIndicator from "../assets/map-indicator.svg";
import send from "../assets/send.svg";
import { Radio } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { API_URL } from '../API/API_URL'
import { SnackbarProvider, enqueueSnackbar } from 'notistack'
import { DotLoaderOverlay } from 'react-spinner-overlay'

function OrderCancelation() {

    const [order, setOrder] = useState(null);
    const [cancelReason, setCancelReason] = useState("");
    const [cancelReasonDescription, setCancelReasonDescription] = useState("");
    const [loading, setLoading] = useState(false);
    const token = localStorage.getItem("token")

    const params = useParams()
    const navigate = useNavigate()

    const headText = {
        color: '#EB252D',
        fontFamily: 'var(--font-gotham-black)',
        fontSize: ' 18px',
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: 'normal',
        margin: "10px"
    }
    const smallText = {
        color: ' var(--Medium_grey, #646982)',
        fontFamily: 'var(--font-gotham)',
        fontSize: '15px',
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: '30px',
        textTransform: 'capitalize',
        margin: "10px"

    }
    const smallText2 = {
        color: ' var(--Medium_grey, #E52823)',
        fontFamily: 'var(--font-gotham)',
        fontSize: '15px',
        fontStyle: 'normal',
        fontWeight: '700',
        textTransform: 'capitalize',
        margin: "50px 0 0 0"

    }
    const date = {
        color: ' var(--Medium_grey, #646982)',
        fontFamily: 'var(--font-gotham)',
        fontSize: '15px',
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: 'normal',
        textTransform: 'capitalize',
        margin: "10px"
    }
    const btnStyle = {
        width: '100%',
        height: '57px',
        borderRadius: '100px',
        border: "none",
        background: '#EB252D',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: ' var(--white-100, #FFF)',
        fontFamily: 'var(--font-gotham)',
        fontSize: ' 16px',
        fontStyle: 'normal',
        fontWeight: '700',
        margin: "75px 0",
        // marginBotton:"px"
    }

    const cancelOrder = async () => {
        setLoading(true)
        try {
            const res = await axios.post(`${API_URL}/cancel-order`, {
                order_id: params.id,
                cancel_reason: cancelReason,
                cancel_reason_description: cancelReasonDescription
            }, {
                headers: {
                    Authorization: "Bearer " + token
                }
            })
            if (res.status === 200) {
                setLoading(false)
                enqueueSnackbar("Order cancel successfully", {
                    variant: "success",
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }
                })
                setTimeout(() => {
                    navigate("/cancelation")
                }, 2000);
            }
        } catch (error) {
            setLoading(false)
            console.log(error);
        }
    }

    async function trackMyOrder() {
        try {
            const response = await axios.get(`${API_URL}/orders-in-progress`, {
                headers: {
                    Authorization: "Bearer " + token
                }
            });
            if (response.status === 200) {
                const foundOrder = response.data.find(order => Number(params.id) === order.o_id);
                if (foundOrder) {
                    setOrder(foundOrder);
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        trackMyOrder()
    }, [])

    return (
        <>
            <DotLoaderOverlay loading={loading} color="red" />
            <SnackbarProvider />
            <BannerNav text="Cancelar pedido" icon={deleteIcon} />
            <div className='container'>
                <div>
                    <div style={{ display: "flex" }}>
                        <div style={{
                            width: '228px',
                            height: '136px'
                        }}>
                            <img className='img-fluid' src={ProductImage} />
                        </div>
                        <div>
                            <div style={{ ...headText, fontSize: 30 }}>{order?.order_id}</div>
                            <div style={date}>{order?.created_at}</div>
                            <div style={headText}>{order?.items[order?.items?.length - 1]?.product_name}</div>
                            <div style={{ margin: "10px" }} className='d-flex  align-items-center'>
                                <div><img src={Star} /></div>
                                <div className='mt-1' style={{ color: "#A5A5A5", fontSize: "14px", fontFamily: "var(--font-gotham)" }}>4.5</div>
                            </div>
                            <div style={smallText}>1x Refresco de uva</div>
                            <div style={smallText}>1x Papa mediana</div>
                        </div>
                    </div>
                    <div style={{ marginTop: "100px" }}>
                        <div style={{ display: "flex", justifyContent: "center", textAlign: "left" }}>
                            <div style={{ textAlign: "left" }}>
                                <p style={{ padding: 0, fontSize: "18px", fontWeight: "700", color: "#EB252D" }}>Motivo</p>
                                <div>
                                    <div>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                            <p style={{ margin: "0 200px 0 0" }}>No llego a tiempo</p>
                                            <Radio
                                                checked={cancelReason === "No llego a tiempo"}
                                                onChange={() => setCancelReason("No llego a tiempo")}
                                                value="No llego a tiempo"
                                                name="cancelReason"
                                                sx={{
                                                    color: 'rgba(235, 37, 45, 1)',
                                                    '&.Mui-checked': {
                                                        color: 'rgba(235, 37, 45, 1)',
                                                    },
                                                }}
                                            />
                                        </div>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                            <p style={{ margin: "0 200px 0 0" }}>Ya no lo quiero</p>
                                            <Radio
                                                checked={cancelReason === "Ya no lo quiero"}
                                                onChange={() => setCancelReason("Ya no lo quiero")}
                                                value="Ya no lo quiero"
                                                name="cancelReason"
                                                sx={{
                                                    color: 'rgba(235, 37, 45, 1)',
                                                    '&.Mui-checked': {
                                                        color: 'rgba(235, 37, 45, 1)',
                                                    },
                                                }}
                                            />
                                        </div>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                            <p style={{ margin: "0 200px 0 0" }}>Orden equivocada</p>
                                            <Radio
                                                checked={cancelReason === "Orden equivocada"}
                                                onChange={() => setCancelReason("Orden equivocada")}
                                                value="Orden equivocada"
                                                name="cancelReason"
                                                sx={{
                                                    color: 'rgba(235, 37, 45, 1)',
                                                    '&.Mui-checked': {
                                                        color: 'rgba(235, 37, 45, 1)',
                                                    },
                                                }}
                                            />
                                        </div>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                            <p style={{ margin: "0 200px 0 0" }}>No es lo que esperaba</p>
                                            <Radio
                                                checked={cancelReason === "No es lo que esperaba"}
                                                onChange={() => setCancelReason("No es lo que esperaba")}
                                                value="No es lo que esperaba"
                                                name="cancelReason"
                                                sx={{
                                                    color: 'rgba(235, 37, 45, 1)',
                                                    '&.Mui-checked': {
                                                        color: 'rgba(235, 37, 45, 1)',
                                                    },
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div style={{ marginTop: "30px" }}>
                                    <textarea
                                        onChange={(e) => setCancelReasonDescription(e.target.value)}
                                        placeholder='Escribe la razon'
                                        style={{
                                            padding: "19px 29px",
                                            width: "400px",
                                            height: "196px",
                                            borderRadius: "10px",
                                            border: "1px solid #EEE",
                                            background: "var(--white-100, #FFF)",
                                            boxShadow: "15px 20px 45px 0px rgba(233, 233, 233, 0.25)",
                                            outline: "none"
                                        }} />
                                    <button style={btnStyle} onClick={cancelOrder}>Cancelar pedido</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OrderCancelation