import React, { useContext, useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import BannerNav from "../components/BannerNav";
import { TotalBox2 } from "../components/TotalBox";
import MethodDelivery from "../components/MethodDelivery";
import ProductImage from "../assets/zinger.png";

import Star from "../assets/star.png";
import Footer from "../components/Footer";
import CustBox from "../components/CustBox";
import backIcon from "../assets/chevron.png";
import { useLocation, useNavigate } from "react-router-dom";
import Modal from "../components/Modal";
import GlobalContext from "../context";
import { Radio } from "@mui/material";
import axios from "axios";
import { API_URL } from "../API/API_URL";
import { DotLoaderOverlay } from "react-spinner-overlay";

function Fifth() {
  const navigate = useNavigate();
  const [width, setWidth] = useState();
  const [open, setOpen] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openEditModal2, setOpenEditModal2] = useState(false);
  const [address, setAddress] = useState(null);
  const [branchAddress, setBranchAddress] = useState(null);
  const { deliveryMethod, setDeliveryMethod } = useContext(GlobalContext);
  const [time, setTime] = useState({ date: null, time: null });
  const [paymentType, setPaymentType] = useState("");
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [branches, setBranches] = useState([]);
  const [userLocations, setUserLocations] = useState([]);
  const location = useLocation();
  const [cartItems, setCartItems] = useState(null);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("currentUser"));

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const headText = {
    color: "#EB252D",
    fontFamily: "var(--font-gotham-black)",
    fontSize: " 18px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
    margin: "10px",
  };
  const smallText = {
    color: " var(--Medium_grey, #646982)",
    fontFamily: "var(--font-gotham)",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
    textTransform: "capitalize",
    margin: "10px",
  };
  const textStyle1 = {
    color: "#323643",
    fontFamily: "var(--font-gotham-black)",
    fontSize: "22px",
    fontStyle: "normal",
    fontWeight: "900",
    lineHeight: "normal",
    margin: "20px 0",
  };
  const textStyle12 = {
    color: " var(--Basic_Red, #E52823)",
    fontFamily: "var(--font-gotham)",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "900",
    lineHeight: "138.836 %" /* 22.214px */,
    letterSpacing: "0.32px",
    margin: "0 0 0 10px",
    padding: 0,
    textAlign: "center",
  };

  async function getCartItems() {
    setLoading(true);
    try {
      const response = await axios.get(`${API_URL}/cart`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      if (response.status === 200) {
        console.log("get cart items response", response);
        setLoading(false);
        setCartItems(response.data);
      }
    } catch (error) {
      setLoading(false);
      console.log("get cart items error", error);
    }
  }

  useEffect(() => {
    getCartItems();
  }, []);

  async function placeOrder() {
    setLoading(true);
    try {
      const res = await axios.post(
        `${API_URL}/place-order`,
        {
          payment_type: "cash",
          order_type: deliveryMethod?.value,
          user_id: user?.id,
          total: cartItems?.total,
          address_id: address?.id,
          branch_address_id: branchAddress?.id,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      if (res.status === 200) {
        setLoading(false);
        navigate("/finali");
      }
    } catch (error) {
      setLoading(false);
    }
  }

  const getBranches = async () => {
    try {
      const response = await axios.get(`${API_URL}/get-branches`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      console.log(response.data);
      setBranches(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getUserLocations = async () => {
    try {
      const response = await axios.get(`${API_URL}/address`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      console.log(response.data);
      setUserLocations(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBranches();
    getUserLocations();
  }, []);

  console.log({
    payment_type: paymentType,
    order_type: deliveryMethod?.value,
    user_id: user?.id,
    total: cartItems?.total,
    address_id: address?.id,
    branch_address_id: branchAddress?.id,
  });

  useEffect(() => {
    localStorage.setItem(
      "order_details",
      JSON.stringify({
        payment_type: "cash",
        order_type: deliveryMethod?.value,
        user_id: user?.id,
        total: cartItems?.total,
        address_id: address?.id,
        branch_address_id: branchAddress?.id,
      })
    );
  }, [paymentType, deliveryMethod, user, cartItems, address, branchAddress]);

  return (
    <>
      <DotLoaderOverlay loading={loading} color="red" />
      <Modal
        open={open}
        setOpen={setOpen}
        branches={branches}
        branchAddress={branchAddress}
        setBranchAddress={setBranchAddress}
        address={address}
        setAddress={setAddress}
        setPaymentType={setPaymentType}
        paymentType={paymentType}
        setTime={setTime}
        time={time}
        placeOrder={placeOrder}
      />
      <BannerNav
        text="Confirmar orden"
        icon={backIcon}
        onClick={() => navigate("/Fourth")}
      />
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-lg-6 col-md-12">
            <div className="d-flex justify-content-center align-items-center">
              <div
                style={{
                  width: "228px",
                  height: "136px",
                }}
              >
                <img className="img-fluid" src={ProductImage} />
              </div>
              <div>
                <div style={headText}>Combo 2 Piezas de Pollo</div>
                <div
                  style={{ margin: "10px" }}
                  className="d-flex  align-items-center"
                >
                  <div>
                    <img src={Star} />
                  </div>
                  <div
                    className="mt-1"
                    style={{
                      color: "#A5A5A5",
                      fontSize: "14px",
                      fontFamily: "var(--font-gotham)",
                    }}
                  >
                    4.5
                  </div>
                </div>
                <div style={smallText}>1x Refresco de uva</div>
                <div style={smallText}>1x Papa mediana</div>
              </div>
            </div>
            <br />
            <br />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CustBox
                userLocations={userLocations}
                address={address}
                setAddress={setAddress}
                selectedLocation={selectedLocation}
                setSelectedLocation={setSelectedLocation}
                openEditModal={openEditModal}
                setOpenEditModal={setOpenEditModal}
                openEditModal2={openEditModal2}
                setOpenEditModal2={setOpenEditModal2}
                setPaymentType={setPaymentType}
                paymentType={paymentType}
              />
            </div>
          </div>
          <div
            className="col-sm-12 col-lg-6 col-md-12"
            style={{
              display: width < 768 && "flex",
              justifyContent: width < 768 && "center",
              alignItems: width < 768 && "center",
              flexDirection: width < 768 && "column",
            }}
          >
            <div
              style={{
                marginLeft: width < 1098 ? "0" : "100px",
                display: width < 1098 ? "flex" : "",
                justifyContent: width < 1098 ? "center" : "",
              }}
            >
              <TotalBox2
                cartItems={cartItems}
                setOpen={setOpen}
                placeOrder={placeOrder}
                time={time}
              />
            </div>
            <div
              style={{
                marginLeft: width < 1098 ? "0" : "100px",
                display: width < 991 ? "flex" : "",
                justifyContent: width < 991 ? "center" : "",
                flexDirection: width < 991 ? "column" : "",
                alignItems: width < 991 ? "center" : "",
              }}
            >
              <div style={{ width: "400px" }}>
                {/* <div className='d-flex justify-content-between align-items-center' style={{ margin: "10px 0" }}>
                                    <div className='d-flex'>
                                        <div ><img src={deliveryMethod?.image} className='img-fluid' style={{ width: "26px", height: "22px" }} /></div>
                                        <div style={{ height: "17px" }}>
                                            <div style={textStyle12}>{deliveryMethod?.value}</div>
                                        </div>
                                    </div>
                                    <div>
                                        <Radio
                                            checked={true}
                                            sx={{
                                                color: 'rgba(235, 37, 45, 1)',
                                                '&.Mui-checked': {
                                                    color: 'rgba(235, 37, 45, 1)',
                                                },
                                            }}
                                        />
                                    </div>
                                </div> */}
                <MethodDelivery />
                <p>{address?.description}</p>
                {/* <div style={{
                                    borderBottom: "1px solid grey"
                                }}>
                                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Fifth;
