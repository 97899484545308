import React from "react";
import MUICarousel from "react-material-ui-carousel";
import { API_URL } from "../API/API_URL";
import axios from "axios";

const Carousel = () => {
  const [banners, setBanners] = React.useState([]);
  const token = localStorage.getItem("token");

  const getCarouselImages = async () => {
    try {
      const response = await axios.get(`${API_URL}/admin/banner`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      if (response.status === 200 && response.data?.length) {
        const currentDateTime = new Date();

        const filteredData = response?.data?.filter((item) => {
          const startDateTime = item?.start_datetime
            ? new Date(item?.start_datetime)
            : null;
          const endDateTime = item?.end_datetime
            ? new Date(item?.end_datetime)
            : null;

          if (startDateTime && endDateTime) {
            return (
              currentDateTime >= startDateTime && currentDateTime <= endDateTime
            );
          }
          return false;
        });

        if (filteredData.length) setBanners(filteredData);
      }
    } catch (error) {
      setBanners([]);
    }
  };

  React.useEffect(() => {
    getCarouselImages();
  }, []);

  return (
    <>
      {banners.length ? (
        <MUICarousel height={400} indicators={banners.length > 1}>
          {banners.map((item, i) => (
            <Item key={i} item={item} />
          ))}
        </MUICarousel>
      ) : null}
    </>
  );
};

function Item({ item }) {
  const [windowSize, setWindowSize] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  return (
    <div className="w-screen" style={{ height: "100%" }}>
      <img
        src={windowSize.width >= 750 ? item.web_image : item.mob_image}
        style={{
          width: "100%",
          height: "100%",
        }}
      />
    </div>
  );
}

export default Carousel;
