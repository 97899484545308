import React, { useContext, useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import FooterBanner from '../components/FooterBanner'
import Slick from "../components/Slick"
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Product from "../assets/product_zing.png";
import Plus from "../assets/plus.png";
import Radio from '@mui/material/Radio';
import Text from "../components/Text"
import Minus from "../assets/minus.png";
import backIcon from "../assets/chevron.png";
import BannerNav from '../components/BannerNav'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { API_URL } from '../API/API_URL'
import axios from 'axios'
import { DotLoaderOverlay } from 'react-spinner-overlay'
import { SnackbarProvider, enqueueSnackbar } from 'notistack'
import RelatedProducts from '../components/relatedProducts'
import GlobalContext from '../context'

function Third() {

    const [selectedValue, setSelectedValue] = useState({ id: "", value: "" });
    const [products, setProducts] = useState([]);
    const [counter, setCounter] = useState(0)
    const [counterType, setCounterType] = useState("")
    const [width, setWidth] = useState()
    const [productDetail, setProductDetail] = useState(null)
    const [loading, setLoading] = useState(false);
    const [likeItem, setLikeItem] = useState(false);
    const [relatedProducts, setRelatedProducts] = useState([])
    const [addionalSelection1, setAddionalSelection1] = useState({ id: "", value: "" });
    const [addional_selection2, setAddionalSelection2] = useState({ id: "", value: "" });
    const { isAuth, setisAuth } = useContext(GlobalContext)
    const token = localStorage.getItem("token")

    const params = useParams()
    const navigate = useNavigate()

    async function addToCart() {
        try {
            const response = await axios.post(`${API_URL}/add-to-cart`, {
                product_id: productDetail?.id,
                additional_selection_id: addionalSelection1.id,
                soft_drink_id: addional_selection2.id,
                quantity: counter
            }, {
                headers: {
                    Authorization: "Bearer " + token
                }
            })
            if (response.status === 200) {
                console.log("add to cart response", response);
                enqueueSnackbar("Item added to cart", {
                    variant: "success",
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }
                })
            }
        } catch (error) {
            console.log("add to cart error", error);
        }
    }

    const getProductDetail = async () => {
        setLoading(true)
        try {
            const response = await axios.get(`${API_URL}/product/${params.id}`, {
                headers: {
                    Authorization: "Bearer " + token
                }
            })
            if (response.status === 200) {
                setLoading(false)
                setProductDetail(response.data)
                console.log("response", response);
            }
        } catch (error) {
            setLoading(false)
            console.log("error", error);
        }
    }

    async function likeProduct() {
        setLikeItem(!likeItem)
        setLoading(true)
        try {
            const response = await axios.post(`${API_URL}/like-unlike-product`, {
                product_id: productDetail?.id,
                like: likeItem
            }, {
                headers: {
                    Authorization: "Bearer " + token
                }
            })
            if (response.status === 200) {
                setLoading(false)
                getProductDetail()
                console.log("response", response);
            }
        } catch (error) {
            setLoading(false)
            console.log("error", error);
        }
    }

    const getRelatedProducts = async () => {
        setLoading(true)
        try {
            const response = await axios.get(`${API_URL}/product-by-category?category_id=${params.id}`, {
                headers: {
                    Authorization: "Bearer " + token
                }
            })
            if (response.status === 200) {
                setLoading(false)
                setRelatedProducts(response.data)
                console.log("response", response);
            }
        } catch (error) {
            setLoading(false)
            console.log("error", error);
        }
    }

    async function likeRelatedProduct(item) {
        setLoading(true)
        try {
            const response = await axios.post(`${API_URL}/like-unlike-product`, {
                product_id: item.id,
                like: !item.like
            }, {
                headers: {
                    Authorization: "Bearer " + token
                }
            })
            if (response.status === 200) {
                setLoading(false)
                getRelatedProducts()
                console.log("response", response);
            }
        } catch (error) {
            setLoading(false)
            console.log("error", error);
        }
    }

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth)
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        getProductDetail()
    }, [params.id])

    console.log(relatedProducts);

    return (
        <>
            <DotLoaderOverlay color="red" loading={loading} />
            <SnackbarProvider />
            <BannerNav text="Detalle del producto" icon={backIcon} onClick={() => navigate("/")} />
            <div className='container' style={{ marginTop: "60px" }}>
                <div className='add-product'>
                    <div>
                        <div style={{ width: width < 768 ? "400px" : "550px", borderRadius: "25px", background: 'var(--white-100, #FFF)', boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.25)', padding: 30 }}>
                            <div onClick={() => {
                                if (token === null) {
                                    setisAuth("NOT AUTHENTICATED")
                                }
                                else {
                                    setisAuth("AUTHENTICATED")
                                    likeProduct()
                                }
                            }} style={{ display: "flex", justifyContent: "flex-end", padding: "20px", cursor: "pointer" }}>
                                {productDetail?.like === 0 ? <FavoriteBorderIcon style={{ fontSize: "30px", cursor: "pointer", color: "red" }} /> : <FavoriteIcon style={{ fontSize: "30px", cursor: "pointer", color: "red" }} />}
                            </div>
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <div style={{ width: "444px", marginTop: "-30px" }}>
                                    <img className='img-fluid' src={productDetail?.image} />
                                </div>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <div onClick={() => {
                                    if (token === null) {
                                        setisAuth("NOT AUTHENTICATED")
                                    }
                                    else {
                                        setisAuth("AUTHENTICATED")
                                        setCounter(counter - 1)
                                        addToCart()
                                    }
                                }} className='d-flex justify-content-center align-items-center' style={{ cursor: "pointer", width: "53px", height: "52px", borderRadius: 'var(--4, 4px)', background: 'var(--white-100, #FFF)', margin: "0 1px", boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.20)' }}>
                                    <img className='img-fluid' src={Minus} />
                                </div>
                                <div className='text_cart d-flex justify-content-center align-items-center' style={{ width: "53px", height: "52px", borderRadius: 'var(--4, 4px)', background: 'var(--white-100, #FFF)', margin: "0 1px", boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.20)' }}>
                                    {counter}
                                </div>
                                <div onClick={async () => {
                                    if (token === null) {
                                        setisAuth("NOT AUTHENTICATED")
                                    }
                                    else {
                                        setisAuth("AUTHENTICATED")
                                        setCounter(counter + 1)
                                        addToCart()
                                    }
                                }}
                                    className='d-flex justify-content-center align-items-center' style={{ cursor: "pointer", width: "53px", height: "52px", borderRadius: 'var(--4, 4px)', background: 'var(--white-100, #FFF)', margin: "0 1px", boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.20)' }}>
                                    <img className='img-fluid' src={Plus} />
                                </div>
                            </div>
                            <div style={{ fontSize: 30, fontWeight: 600 }} className='d-flex justify-content-center align-items-center mt-4'>
                                {productDetail?.name}
                            </div>
                            <div className='d-flex justify-content-center align-items-center mt-3'>
                                <div className='cart_small' style={{ width: '399px' }}>
                                    2 Piezas de Pollo + Papa Mediana o Arepitas Medianas + Refresco 12 onzas.
                                </div>
                            </div>
                            <div className='d-flex justify-content-center align-items-center mt-4'>
                                <div className='cart_price'>$ {productDetail?.retail_price}</div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className='additional-item-container'>
                            <div className='card_head_cart'>
                                Seleccionar adicional
                            </div>
                            <div style={{ width: "250px", margin: "0 auto" }}>
                                {productDetail?.product_addtional_selections?.map((item, index) => {
                                    return item?.addional_selections?.addional_selection_values?.map((addional_selection, ind) => {
                                        return (
                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                <div className='cart_smal_head_cart'>{addional_selection?.value}</div>
                                                <div>
                                                    <Radio
                                                        checked={addional_selection.id === addionalSelection1.id}
                                                        onChange={() => {
                                                            setAddionalSelection1({ id: addional_selection.id, value: addional_selection.value })
                                                        }}
                                                        sx={{
                                                            color: 'rgba(235, 37, 45, 1)',
                                                            '&.Mui-checked': {
                                                                color: 'rgba(235, 37, 45, 1)',
                                                            },
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    })
                                })}
                                {/* <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <div className='cart_smal_head_cart'>Arepitas Medianas</div>
                                    <div>
                                        <Radio
                                            {...controlProps('e')}
                                            sx={{
                                                color: 'rgba(235, 37, 45, 1)',
                                                '&.Mui-checked': {
                                                    color: 'rgba(235, 37, 45, 1)',
                                                },
                                            }}
                                        />
                                    </div>
                                </div> */}
                            </div>
                            <div style={{ border: "1px solid var(--Medium_grey, #646982)" }}></div>

                            <br />
                            <br />

                            <div className='card_head_cart'>
                                Seleccionar refresco
                            </div>
                            <div style={{ width: "250px", margin: "0 auto" }}>

                                {productDetail?.product_soft_drinks?.map((item, index) => {
                                    return item?.soft_drinks?.soft_drink_values?.map((soft_drink, ind) => {
                                        return (
                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                <div className='cart_smal_head_cart'>{soft_drink?.value}</div>
                                                <div>
                                                    <Radio
                                                        checked={soft_drink.id === addional_selection2.id}
                                                        onChange={() => {
                                                            setAddionalSelection2({ id: soft_drink.id, value: soft_drink.value })
                                                        }}
                                                        sx={{
                                                            color: 'rgba(235, 37, 45, 1)',
                                                            '&.Mui-checked': {
                                                                color: 'rgba(235, 37, 45, 1)',
                                                            },
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    })
                                })}

                                {/* <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <div className='cart_smal_head_cart'>Uva</div>
                                    <div>
                                        <Radio
                                            {...controlProps('e')}
                                            sx={{
                                                color: 'rgba(235, 37, 45, 1)',
                                                '&.Mui-checked': {
                                                    color: 'rgba(235, 37, 45, 1)',
                                                },
                                            }}
                                        />
                                    </div>
                                </div> */}

                                {/* <div style={{ width: "250px", border: "1px solid var(--Medium_grey, #646982)" }}></div> */}




                            </div>
                            <div className='mt-2' style={{ border: "1px solid var(--Medium_grey, #646982)" }}></div>

                        </div>
                        <button onClick={() => navigate("/Fourth")} style={{ display: "flex", justifyContent: "center", alignItems: "center", color: "#fff", width: "308px", border: "none", outline: "none", background: 'var(--Basic_Red, #E52823)', borderRadius: "100px", padding: "10px", marginTop: "-80px" }}>
                            Ingresar
                        </button>
                    </div>
                </div>
            </div >
            <br />
            <div className='mt-5'>
                <Text color='#32343E' text="Articulos relacionados" font="35px" />
            </div>
            <RelatedProducts id={params.id} />
            <Text color='#32343E' text="Ofertas" font="35px" />
        </>
    )
}
export default Third