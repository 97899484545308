import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import BannerNav from '../components/BannerNav'
import Product from "../assets/deal.png"
import LightLocation from "../assets/lightLocation.png"
import Delete from "../assets/deletebin.png"
import pedidosIcon from "../assets/pedidos-icon.svg"
import Sidebar from '../components/Sidebar'
import settingIcon from '../assets/settings.svg';
import addCircle from '../assets/addCircleRed.svg';
import { API_URL } from '../API/API_URL'
import axios from 'axios'
import { DotLoaderOverlay } from 'react-spinner-overlay'
import { useNavigate } from 'react-router-dom'
import { SnackbarProvider, enqueueSnackbar } from 'notistack'

function Pedidos() {

    const [state, setState] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [myOrders, setMyOrders] = useState([])
    const navigate = useNavigate()

    const boxStyle = {
        width: '500px',
        // height: '89px',
        borderRadius: '6px',
        background: 'var(--white - 100, #FFF)',
        boxShadow: ' 0px 2px 6px 0px rgba(0, 0, 0, 0.20)',
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        padding:"30px"
    }
    const Image = {
        width: "94px",
        height: "90px"
    }
    const timeStyle = {
        // color: '',
        fontFamily: 'var(--font-gotham)',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '900',
        lineHeight: 'normal',
        margin:"5px 0"
        // width: "196px"
    }
    const textStyle = {
        color: ' #ADADAF',
        fontFamily: 'var(--font-gotham)',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: '900',
        lineHeight: 'normal',

    }
    const btnStyle = {
        width: '100%',
        height: '45px',
        margin: "3px 2px",
        // margin: "5px",
        // transform: ' rotate(90deg)',
        borderRadius: ' var(--4, 4px)',
        background: 'var(--white - 100, #FFF)',
        boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.20)',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: ' #EB2830',
        fontFamily: 'var(--font-gotham)',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '900',
        lineHeight: 'normal',
        cursor: "pointer"
    }
    const btnStyleFinal = {
        width: '308px',
        height: '57px',
        borderRadius: '100px',
        background: '#EB252D',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: ' var(--white-100, #FFF)',
        fontFamily: 'var(--font-gotham)',
        fontSize: ' 16px',
        fontStyle: 'normal',
        fontWeight: '900',
        cursor: "pointer"

        // marginBotton:"px"
    }

    const token = localStorage.getItem("token")

    async function getMyOrders() {
        setLoading(true)
        try {
            const response = await axios.get(`${API_URL}/orders-in-progress`, {
                headers: {
                    Authorization: "Bearer " + token
                }
            })
            if (response.status === 200) {
                console.log(response);
                setMyOrders(response.data)
                setLoading(false)
            }
        } catch (error) {
            console.log(error);
            setLoading(false)
        }
    }

    async function handleDeleteOrder(id) {
        setLoading(true)
        try {
            const response = await axios.delete(`${API_URL}/admin/orders/delete/${id}`, {
                headers: {
                    Authorization: "Bearer " + token
                }
            })
            if (response.status === 200) {
                console.log(response);
                enqueueSnackbar("Order deleted successfully", {
                    variant: "success",
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }
                })
                getMyOrders()
                setLoading(false)
            }
        } catch (error) {
            console.log(error);
            setLoading(false)
        }
    }

    useEffect(() => {
        getMyOrders()
    }, [])

    console.log(myOrders);

    return (
        <>
            <DotLoaderOverlay color="red" loading={loading} />
            <SnackbarProvider />
            {/* <Navbar /> */}
            <div
                style={{
                    position: "relative",
                    cursor: "pointer"
                }}
            >
                <div
                    onClick={() => setState(true)}
                    style={{
                        position: "absolute",
                        backgroundColor: "red",
                        borderRadius: "100%",
                        width: "60px",
                        height: "60px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        top: 100,
                        right: 20,
                        zIndex: 1000
                    }}>
                    <img src={settingIcon} />
                </div>
            </div>
            <BannerNav text="Mis Pedidos" icon={pedidosIcon} />
            <div className='d-flex justify-content-center flex-column align-items-center'>
                {myOrders.map((order) => {
                    return order?.items?.map((item, index) => {
                        return (
                            <div className='my-3'>
                                <div style={boxStyle}>
                                    <div style={{marginRight:"30px"}} onClick={() => navigate(`/order-details/${order.o_id}`)}><img className='img-fluid' src={Product} alt="" /></div>
                                    <div onClick={() => navigate(`/order-details/${order.o_id}`)} className='d-flex flex-column justify-content-center align-items-start gap-1'>
                                        <div style={timeStyle}># {order.o_id}</div>
                                        <div style={timeStyle}>{order?.created_at}</div>
                                        <div style={textStyle}>{order?.items[order?.items?.length - 1]?.product_name}</div>
                                        {/* <div className='d-flex justify-content-start align-items-center'> */}
                                        {/* <div style={{ width: "40px", height: "40px", }}><img className='w-100 h-100' src={LightLocation} /></div> */}
                                        {/* </div> */}
                                        <div style={timeStyle}>{order?.user_address}</div>
                                        <div style={timeStyle}>$ {order?.total}</div>
                                    </div>
                                    <div onClick={() => handleDeleteOrder(order.o_id)} style={{ marginTop: "-40px", cursor: "pointer" }}><img src={Delete} width={24} height={24} /></div>
                                </div>
                                <div style={{ display: "flex" }}>
                                    <div style={btnStyle}>Comentar</div>
                                    <div style={btnStyle}> <img src={addCircle} alt="" /> Repetir</div>
                                </div>
                            </div>
                        )
                    })
                })}
                <div style={btnStyleFinal}>
                    volver
                </div>
            </div>
            <Sidebar state={state} setState={setState} />
        </>
    )
}

export default Pedidos