// import { useState, useEffect, useRef } from 'react';
// import Button from '@mui/material/Button';
// import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';
// import useMediaQuery from '@mui/material/useMediaQuery';
// import { useTheme, } from '@mui/material/styles';
// import Cross from "../assets/cut.png"
// import Map from "../assets/map.png"
// import Text from './Text';
// import Location from "../assets/location.png"
// import LightLocation from "../assets/location-point.svg"
// import rating from "../assets/rating.svg"
// import BannerShort from "../assets/banner_Short.png"
// import Logo from "../assets/loginlogo.png"
// import visa from "../assets/visa-icon.svg"
// import chevronDown from "../assets/chevron-down-2.svg"
// import masterCard from "../assets/master-card.svg"
// import add from "../assets/addCircle.svg"
// import { useNavigate } from 'react-router-dom';
// import Calendar from 'react-calendar';
// import 'react-calendar/dist/Calendar.css';
// import BasicTimePicker from './TimePIcker';
// import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
// import Geocode from 'react-geocode';

// function Modal(props) {

//     const {
//         open,
//         setOpen,
//         address,
//         setAddress,
//         setPaymentType,
//         paymentType,
//         setTime,
//         time
//     } = props

//     const theme = useTheme();
//     const [open2, setOpen2] = useState(false);
//     const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
//     const [value, onChange] = useState(new Date());
//     const [width, setWidth] = useState()
//     const [next, setNext] = useState(0)
//     const [paymentForm, setPaymentForm] = useState({
//         MerchantId: '39038540035',
//         MerchantName: 'Prueba AZUL',
//         MerchantType: 'E-Commerce',
//         CurrencyCode: '$',
//         OrderNumber: '001',
//         Amount: '10000',
//         ITBIS: '000',
//         ApprovedUrl: 'https://google.com/',
//         DeclinedUrl: 'https://google.com/',
//         CancelUrl: 'https://google.com/',
//         UseCustomField1: '0',
//         CustomField1Label: '',
//         CustomField1Value: '',
//         UseCustomField2: '0',
//         CustomField2Label: '',
//         CustomField2Value: '',
//         AltMerchantName: 'prueba',
//         AuthHash: '86e90475e6dc7c6d6eb98e549bb97cb8aca25beeb44b967c5d4c8d95be91c08d0e9847b1a51a3321e8a6ead72a79e95ecd84f3c261d2f6be8737fed272737325'
//     })
//     const LATITUDE = 29.9417666;
//     const LONGITUDE = -95.3991524;
//     const LATITUDE_DELTA = 0.0922;
//     const LONGITUDE_DELTA = 0.0421;
//     const SPACE = 0.01;
//     const mapRef = useRef();
//     const [mapCenter, setMapCenter] = useState({ lat: 18.7357, lng: -70.1627 });
//     const [region, setRegion] = useState({
//         latitude: 18.4338645,
//         longitude: -68.9658817,
//         latitudeDelta: LATITUDE_DELTA,
//         longitudeDelta: LATITUDE_DELTA,
//     });
//     const navigate = useNavigate()
//     const restaurants = [
//         {
//             description: 'Avenida Winston Churchill, Santo Domingo',
//             id: 1,
//             latitude: 18.4722344,
//             longitude: -69.9419302,
//             name: 'Av. Winston Churchill. Multicentro Churchill, La Sirena',
//         },
//         {
//             description:
//                 'Av. Tiradentes, Gasolinera Sunix , casi esquina Roberto Pastoriza',
//             id: 2,
//             latitude: 18.4819735,
//             longitude: -69.9288782,
//             name: 'Av. Tiradentes, Gasolinera Sunix',
//         },
//         {
//             description: 'Av. Luperón. Estación Shell',
//             id: 3,
//             latitude: 18.4653429,
//             longitude: -69.9685964,
//             name: 'Av. Luperón. Estación Shell',
//         },
//         {
//             description: 'Jumbo Av. Luperón Food Court',
//             id: 4,
//             latitude: 18.4653429,
//             longitude: -69.9685964,
//             name: 'Jumbo Av. Luperón Food Court',
//         },
//         {
//             // this one
//             description: 'Av. España, Estación Texaco La Marina',
//             id: 5,
//             latitude: 37.78825,
//             longitude: -122.4324,
//             name: 'Av. España, Estación Texaco La Marina',
//         },
//         {
//             description: 'Carretera Mella Esq. San Vicente de Paul',
//             id: 6,
//             latitude: 18.5059946,
//             longitude: -69.85655899999999,
//             name: 'Megacentro',
//         },
//         {
//             description: 'Santo Domingo, Dominican Republic',
//             id: 7,
//             latitude: 18.4498822,
//             longitude: -69.96861369999999,
//             name: '27 de Febrero con Caonabo',
//         },
//         {
//             description: 'Av. Winston Churchill con Roberto Pastoriza',
//             id: 8,
//             latitude: 18.4660662,
//             longitude: -69.93816269999999,
//             name: 'Av. Winston Churchill con Roberto Pastoriza',
//         },

//         {

//             description:
//                 'Restaurante Pollos Victorina , Local 4 PLAZA CUADRA, San Isidro. Av. Rafael Tomas Fernandez Dominguez, Santo Domingo este.(Después de la planta de Hielo Alaska y del Nuevo Supermercado Jumbo)',
//             id: 9,
//             latitude: 18.487301,
//             longitude: -69.8280386,
//             name: 'POLLOS VICTORINA SAN ISIDRO',
//         },
//         {
//             description:
//                 'Nueva sucursal en la estación de combustible sunix en la 27 de febrero, entre la Abraham Lincoln y la Tiradentes',
//             id: 10,
//             latitude: 18.4712378,
//             longitude: -69.925242,
//             name: 'Bomba Sunix. Av 27 de Febrero',
//         },
//         {
//             description:
//                 'Avn. John F. Kennedy esquina Tiradentes, estación de combustible Sunix',
//             id: 11,
//             latitude: 40.6446245,
//             longitude: -73.7797035,
//             name: 'POLLOS VICTORINA JFK',
//         },
//         {
//             description: 'Av. Jonh F. Kennedy, Plaza Galería 360, Santo Domingo',
//             id: 12,
//             latitude: 18.4850603,
//             longitude: -69.9360289,
//             name: 'Galería 360',
//         },
//         {
//             description:
//                 'Visita nuestra nueva sucursal en Satiago AV. 27 de Febrero Esq. Calle Maimón',
//             id: 13,
//             latitude: 19.4603448,
//             longitude: -70.6870114,
//             name: 'Pollos Victorina Santiago',
//         },

//         {
//             description: 'Aeropuerto Internacional Cibao',
//             id: 14,
//             latitude: 19.4021334,
//             longitude: -70.6019104,
//             name: 'Pollos Victorina',
//         },
//         {
//             description:
//                 'Estación de Combustible United Petroleum, Boulevard Turístico del Este.',
//             id: 15,
//             latitude: 18.5600761,
//             longitude: -68.372535,
//             name: 'Punta Cana',
//         },
//         {
//             description: 'Plaza San Juan Shopping Center, Av Barcelo, Punta Cana',
//             id: 16,
//             latitude: 18.6853385,
//             longitude: -68.4501446,
//             name: 'Bávaro',
//         },
//         {
//             description:
//                 'Plaza Pueblo Bavaro, Entrada a Pueblo Bavaro, Av. Barcelo, entre Bavaro y Veron',
//             id: 17,
//             latitude: 18.6076879,
//             longitude: -68.4114743,
//             name: 'POLLOS VICTORINA PUEBLO BAVARO',
//         },
//         {
//             description: 'Calle José A. Santana, Multiplaza, La Sirena, Higuey',
//             id: 18,
//             latitude: 18.6127833,
//             longitude: -68.71544999999999,
//             name: 'Higuey',
//         },
//         {
//             description: 'Avenida Liberta esquina Dr. Gonzalvo #3',
//             id: 19,
//             latitude: 18.4187107,
//             longitude: -68.9660873,
//             name: 'Jumbo, La Romana',
//         },
//         {
//             description:
//                 'Av. Circunvalación, Esq. Padre Abreu, Multiplaza , La Sirena, La Romana',
//             id: 20,
//             latitude: 18.4338645,
//             longitude: -68.9658817,
//             name: 'La Romana',
//         },
//     ];

//     const handleClose = () => {
//         setOpen(false);
//     };

//     const textStyle = {
//         color: ' #EB2830',
//         fontFamily: 'var(--font-gotham-black)',
//         fontSize: ' 22px',
//         fontStyle: 'normal',
//         fontWeight: '700',
//         lineHeight: '22px',
//         margin: width < 768 && "0"
//     }
//     const smallText = {
//         color: ' var(--Dark_Grey, #32343E)',
//         fontFamily: 'var(--font-gotham)',
//         fontSize: '16px',
//         fontStyle: 'normal',
//         fontWeight: '900',
//         margin: "0 0 0 10px",
//         lineHeight: "normal"
//     }
//     const btnStyle = {
//         width: '353px',
//         height: '57px',
//         borderRadius: '100px',
//         background: '#EB252D',
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//         cursor: "pointer",
//         // marginBotton:"px"
//     }
//     const btnStyle2 = {
//         width: '100%',
//         fontFamily: 'var(--font-gotham)',
//         borderRadius: '100px',
//         background: '#EB252D',
//         outline: "none",
//         border: "none",
//         color: "white",
//         fontWeight: "900",
//         padding: "20px 0"
//     }

//     const getLocationLL = async (address) => {
//         try {
//             const response = await Geocode.fromAddress(address.address);
//             const { results, status } = response;
//             if (status === 'OK') {
//                 const { lat, lng } = results[0].geometry.location;
//                 const newRegion = {
//                     latitude: lat,
//                     longitude: lng,
//                     latitudeDelta: LATITUDE_DELTA,
//                     longitudeDelta: LATITUDE_DELTA,
//                 };
//                 setMapCenter({ lat, lng });
//                 setRegion(newRegion);
//                 mapRef.current.panTo({ lat, lng });
//                 mapRef.current.setZoom(13);
//             } else {
//                 console.warn('Geocode API request failed with status:', status);
//             }
//             console.log(response);
//         } catch (error) {
//             console.error('Error fetching location:', error);
//         }
//     };

//     useEffect(() => {
//         const handleResize = () => {
//             setWidth(window.innerWidth)
//         };
//         window.addEventListener("resize", handleResize);
//         return () => {
//             window.removeEventListener("resize", handleResize);
//         };
//     }, []);

//     console.log(address);

//     return (
//         <>
//             <Dialog
//                 open={open}
//                 onClose={handleClose}
//                 aria-labelledby="responsive-dialog-title"
//                 className='dialog'
//                 PaperProps={{
//                     style: {
//                         borderRadius: "28px",
//                         background: 'var(--white-100, #FFF)',
//                         boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.25)',
//                         width: "100%"
//                     },
//                 }}
//             >

//                 {next === 0 ? (
//                     <>
//                         {/* <img className='img-fluid' style={{ cursor: "pointer" }} onClick={handleClose} width={27} height={27} src={Cross} alt="cross" /> */}
//                         <DialogTitle className='d-flex justify-content-end cursor-pointer' id="responsive-dialog-title">
//                             <LoadScript googleMapsApiKey="AIzaSyABy0de4oAU34qkNVvF4xiiVmvS9zdeiMY">
//                                 <GoogleMap
//                                     ref={mapRef}
//                                     mapContainerStyle={{ height: '400px', width: '100%' }}
//                                     center={mapCenter}
//                                     zoom={13}
//                                     region={region}
//                                     initialRegion={{
//                                         latitude: 18.4338645,
//                                         longitude: -68.9658817,
//                                         latitudeDelta: 0.0922,
//                                         longitudeDelta: 0.0421,
//                                     }}
//                                 >
//                                     {restaurants.map((restaurant) => (
//                                         <Marker
//                                             icon={"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAMAAABF0y+mAAAAh1BMVEX////zjY/sP0f5wsP0mpz1n6H/+vruVFnqHSvrKjTrIzD6z9D97+/2pKfuUFXuTVP3tbfxcHXxdnr6zc/719f+9fXsOULyfoL/+Pj96+vuWF7qGij95ub729384OHvXmTzkpbyhYj3sbPwZWrsQ0v5x8fwbXHsNT72qavwZ2/4vrn3tLf0j5UFPw9iAAABQUlEQVR4AZXOVWLDMBAE0JFBDkhmklEyu8n9z9eYyvg+F2YXf0M03TDxNWqdtPPlii8wTmznfLlYuuv5AfFCvIj8OE5SKq7ZKTdoQcoqK7BLuWPXcg2wnQgL1TBs/LjVWh2LoMaKE8fFoqy72Irl0tJZ1kdYlRnFgxwcy6sTABWrhtbFZlifSmKny1QbrLc0He+aw6iknHpeiYrkNXaRxdZYkmLRt91wtreTYRH4WFQ9UgRJAVYEebYNFma956tUkZkIALSvjtQBG/2Rx7EpUqyqGTt3wCEkdJ2vQ+yob+IwqWgJMPCicJrXwUEAaPFKtIodXTJyl7t4IyL2RLerQ2tOId67Dg6PwFRsiieGj9Imc4gzXwFB8VmqmzrFP1GjpJSVELfp1oiPifJ+N9yEl/fZMAQ+CIqyDEqRluW1Z/iDZ03hGHJ5J3VuAAAAAElFTkSuQmCC"}
//                                             onPress={() => {
//                                                 getLocationLL(restaurant)
//                                             }}
//                                             coordinate={{ latitude: restaurant?.latitude, longitude: restaurant?.longitude }}
//                                             title={restaurant?.name}
//                                             description={restaurant?.description}
//                                             key={restaurant.id}
//                                             position={{ lat: restaurant.latitude, lng: restaurant.longitude }}
//                                             onClick={() => setAddress(restaurant)}
//                                         />
//                                     ))}
//                                 </GoogleMap>
//                             </LoadScript>
//                         </DialogTitle>
//                         <DialogContentText style={{ padding: width < 768 && "30px" }}>
//                             <div style={{ padding: "0 30px" }}>
//                                 <div style={textStyle}>
//                                     Para retirar
//                                 </div>
//                                 {restaurants.map((restaurant) => {
//                                     return (
//                                         <div className='d-flex mb-3 mt-3'>
//                                             <div><img src={Location} /></div>
//                                             <div style={{ ...smallText, color: address.id === restaurant.id ? "red" : "grey" }}>{restaurant.description}</div>
//                                         </div>
//                                     )
//                                 })}
//                             </div>
//                         </DialogContentText>
//                         <DialogActions className='d-flex justify-content-center mb-3'>
//                             <div style={btnStyle}>
//                                 <div><img width={29} height={29} src={LightLocation} /></div>
//                                 <div onClick={() => setNext(1)} style={{
//                                     color: 'var(--white-100, #FFF)',
//                                     fontFamily: 'var(--font-gotham )',
//                                     fontSize: '16px',
//                                     fontStyle: 'normal',
//                                     fontWeight: '700',
//                                     lineHeight: 'normal',
//                                 }}>Guardar ubicación </div>
//                             </div>
//                         </DialogActions>
//                     </>
//                 ) : next === 1 ? (
//                     <DialogTitle id="responsive-dialog-title" style={{ padding: "0", position: 'relative' }}>
//                         <div>
//                             <div className='chat-bg'>
//                                 <p style={{
//                                     fontSize: "22px",
//                                     fontWeight: "900",
//                                     fontFamily: 'var(--font-gotham)',
//                                     padding: "44px 0 0 62px",
//                                     color: "#EB252D"
//                                 }}>Programar pedido</p>
//                             </div>
//                             <div style={{ position: 'absolute', top: "20px", right: "20px" }}>
//                                 <img className='img-fluid' style={{ cursor: "pointer" }} onClick={handleClose} width={27} height={27} src={Cross} alt="cross" />
//                             </div>
//                         </div>
//                         <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
//                             <Calendar onChange={(e) => setTime({ ...time, date: new Date(e).toLocaleDateString() })} value={time?.date} />
//                         </div>
//                         <div style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "50px 0 0 0" }}>
//                             <BasicTimePicker setTime={setTime} time={time} />
//                         </div>
//                         <div style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "50px 0" }}>
//                             <button onClick={() => setNext(2)} style={{
//                                 width: "353px",
//                                 height: "59px",
//                                 backgroundColor: "#EB252D",
//                                 border: "none",
//                                 fontWeight: "900",
//                                 fontFamily: 'var(--font-gotham)',
//                                 color: "#FFF",
//                                 borderRadius: "30px"
//                             }}>Aceptar</button>
//                         </div>
//                     </DialogTitle>
//                 ) : next === 2 ? (
//                     <>
//                         <DialogTitle id="responsive-dialog-title" style={{ padding: "0px", position: 'relative', height: "80vh" }}>
//                             <div className='chat-bg'>
//                                 <p style={{
//                                     color: '#EB252D',
//                                     fontFamily: 'var(--font-gotham )',
//                                     fontSize: '22px',
//                                     fontStyle: 'normal',
//                                     fontWeight: '900',
//                                     lineHeight: 'normal',
//                                     padding: "50px 80px"
//                                 }}>Métodos de pago</p>
//                             </div>
//                             <div style={{ position: 'absolute', top: "20px", right: "20px" }}>
//                                 <img className='img-fluid' style={{ cursor: "pointer" }} onClick={handleClose} width={27} height={27} src={Cross} alt="cross" />
//                             </div>
//                             <div>
//                                 <div style={{ display: "flex", justifyContent: "center" }}>
//                                     <div style={{ textAlign: "center", margin: "0 16px", cursor: "pointer" }}>
//                                         <div style={{
//                                             padding: "20px",
//                                             backgroundColor: "#F0F5FA",
//                                             borderRadius: "15px"
//                                         }}>
//                                             <img src={visa} />
//                                         </div>
//                                         <p>Visa</p>
//                                     </div>
//                                     <div style={{ textAlign: "center", margin: "0 16px", cursor: "pointer" }}>
//                                         <div style={{
//                                             padding: "20px",
//                                             backgroundColor: "#F0F5FA",
//                                             borderRadius: "15px"
//                                         }}>
//                                             <img src={visa} />
//                                         </div>
//                                         <p>Mastercard</p>
//                                     </div>
//                                 </div>
//                                 <div style={{ padding: width < 768 ? "0 50px" : "0 100px", margin: "50px 0 0 0" }}>
//                                     {/* <div style={{ margin: "0 0 30px 0" }} onClick={() => setPaymentType("Cash")}>
//                                         <div style={{
//                                             padding: "20px",
//                                             backgroundColor: "#F0F5FA",
//                                             borderRadius: "15px",
//                                             display: "flex",
//                                             justifyContent: "space-between",
//                                             cursor: "pointer",
//                                             border: paymentType === "Cash" ? "2px solid red" : ""
//                                         }}>
//                                             <div>
//                                                 <p style={{ margin: 0 }}>Cash</p>
//                                                 <div style={{ display: "flex", alignItems: "center" }}>
//                                                     <img src={masterCard} />
//                                                     <p style={{ margin: "0 10px" }}>***********</p>
//                                                     <p style={{ margin: 0 }}>436</p>
//                                                 </div>
//                                             </div>
//                                             <div>
//                                                 <img src={chevronDown} />
//                                             </div>
//                                         </div>
//                                     </div> */}
//                                     <div onClick={() => setPaymentType("Mastercard")}>
//                                         <div style={{
//                                             padding: "20px",
//                                             backgroundColor: "#F0F5FA",
//                                             borderRadius: "15px",
//                                             display: "flex",
//                                             justifyContent: "space-between",
//                                             cursor: "pointer",
//                                             border: paymentType === "Mastercard" ? "2px solid red" : ""
//                                         }}>
//                                             <div>
//                                                 <p style={{ margin: 0 }}>Mastercard</p>
//                                                 <div style={{ display: "flex", alignItems: "center" }}>
//                                                     <img src={masterCard} />
//                                                     <p style={{ margin: "0 10px" }}>***********</p>
//                                                     <p style={{ margin: 0 }}>332</p>
//                                                 </div>
//                                             </div>
//                                             <div>
//                                                 <img src={chevronDown} />
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                                 <div style={{ padding: width < 768 ? "0 50px" : "0 100px" }}>
//                                     <div style={{ textAlign: "center", margin: "70px 0 26px 0" }}>
//                                         <form
//                                             action="https://pruebas.azul.com.do/PaymentPage/"
//                                             method="post"
//                                             id="paymentForm"
//                                             name="paymentForm"
//                                         >
//                                             <input
//                                                 type="hidden"
//                                                 id="MerchantId"
//                                                 name="MerchantId"
//                                                 defaultValue={paymentForm.MerchantId}
//                                             />
//                                             <input
//                                                 type="hidden"
//                                                 id="MerchantName"
//                                                 name="MerchantName"
//                                                 defaultValue={paymentForm.MerchantName}
//                                             />
//                                             <input
//                                                 type="hidden"
//                                                 id="MerchantType"
//                                                 name="MerchantType"
//                                                 defaultValue={paymentForm.MerchantType}
//                                             />
//                                             <input type="hidden" id="CurrencyCode" name="CurrencyCode" defaultValue={paymentForm.CurrencyCode} />
//                                             <input type="hidden" id="OrderNumber" name="OrderNumber" defaultValue={paymentForm.OrderNumber} />
//                                             <input type="hidden" id="Amount" name="Amount" defaultValue={paymentForm.Amount} />
//                                             <input type="hidden" id="ITBIS" name="ITBIS" defaultValue={paymentForm.ITBIS} />
//                                             <input
//                                                 type="hidden"
//                                                 id="ApprovedUrl"
//                                                 name="ApprovedUrl"
//                                                 defaultValue={paymentForm.ApprovedUrl}
//                                             />
//                                             <input
//                                                 type="hidden"
//                                                 id="DeclinedUrl"
//                                                 name="DeclinedUrl"
//                                                 defaultValue={paymentForm.DeclinedUrl}
//                                             />
//                                             <input
//                                                 type="hidden"
//                                                 id="CancelUrl"
//                                                 name="CancelUrl"
//                                                 defaultValue={paymentForm.CancelUrl}
//                                             />
//                                             <input
//                                                 type="hidden"
//                                                 id="UseCustomField1"
//                                                 name="UseCustomField1"
//                                                 defaultValue={paymentForm.UseCustomField1}
//                                             />
//                                             <input
//                                                 type="hidden"
//                                                 id="CustomField1Label"
//                                                 name="CustomField1Label"
//                                                 defaultValue={paymentForm.CustomField1Label}
//                                             />
//                                             <input
//                                                 type="hidden"
//                                                 id="CustomField1Value"
//                                                 name="CustomField1Value"
//                                                 defaultValue={paymentForm.CustomField1Value}
//                                             />
//                                             <input
//                                                 type="hidden"
//                                                 id="UseCustomField2"
//                                                 name="UseCustomField2"
//                                                 defaultValue={paymentForm.UseCustomField2}
//                                             />
//                                             <input
//                                                 type="hidden"
//                                                 id="CustomField2Label"
//                                                 name="CustomField2Label"
//                                                 defaultValue={paymentForm.CustomField2Label}
//                                             />
//                                             <input
//                                                 type="hidden"
//                                                 id="CustomField2Value"
//                                                 name="CustomField2Value"
//                                                 defaultValue={paymentForm.CustomField2Value}
//                                             />
//                                             <input
//                                                 type="hidden"
//                                                 id="AltMerchantName"
//                                                 name="AltMerchantName"
//                                                 defaultValue={paymentForm.AltMerchantName}
//                                             />
//                                             <input
//                                                 type="hidden"
//                                                 id="AuthHash"
//                                                 name="AuthHash"
//                                                 defaultValue={paymentForm.AuthHash}
//                                             />
//                                             <button type='submit' style={btnStyle2}>Proceder al pago</button>
//                                         </form>
//                                     </div>
//                                     <div onClick={() => {
//                                         navigate("/fifth")
//                                         setOpen(false)
//                                     }} style={{ textAlign: "center", margin: "0 0 0 0", cursor: "pointer" }}>
//                                         <p> <img src={add} /> Agregar Método de Pago</p>
//                                     </div>
//                                 </div>
//                             </div>
//                         </DialogTitle>
//                     </>
//                 ) : ""}
//             </Dialog>
//         </>
//     );
// }

// export default Modal;

import { useState, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Cross from "../assets/cut.png";
import Map from "../assets/map.png";
import Text from "./Text";
import Location from "../assets/location.png";
import LightLocation from "../assets/location-point.svg";
import rating from "../assets/rating.svg";
import BannerShort from "../assets/banner_Short.png";
import Logo from "../assets/loginlogo.png";
import visa from "../assets/visa-icon.svg";
import chevronDown from "../assets/chevron-down-2.svg";
import masterCard from "../assets/master-card.svg";
import add from "../assets/addCircle.svg";
import { useNavigate } from "react-router-dom";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import BasicTimePicker from "./TimePIcker";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import Geocode from "react-geocode";
import axios from "axios";
import { API_URL } from "../API/API_URL";
import logoImage from "../assets/branch-icon.png";
import PaymentForm from "./paymentForm";

function Modal(props) {
  const {
    open,
    setOpen,
    branchAddress,
    setBranchAddress,
    address,
    branches,
    setAddress,
    setPaymentType,
    paymentType,
    placeOrder,
    setTime,
    time,
  } = props;

  const theme = useTheme();
  const token = localStorage.getItem("token");
  const [open2, setOpen2] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [value, onChange] = useState(new Date());
  const [width, setWidth] = useState();
  const [next, setNext] = useState(0);
  const [paymentForm, setPaymentForm] = useState({
    MerchantId: "39038540035",
    MerchantName: "Prueba AZUL",
    MerchantType: "E-Commerce",
    CurrencyCode: "$",
    OrderNumber: "001",
    Amount: "10000",
    ITBIS: "000",
    ApprovedUrl: "https://google.com/",
    DeclinedUrl: "https://google.com/",
    CancelUrl: "https://google.com/",
    UseCustomField1: "0",
    CustomField1Label: "",
    CustomField1Value: "",
    UseCustomField2: "0",
    CustomField2Label: "",
    CustomField2Value: "",
    AltMerchantName: "prueba",
    AuthHash:
      "86e90475e6dc7c6d6eb98e549bb97cb8aca25beeb44b967c5d4c8d95be91c08d0e9847b1a51a3321e8a6ead72a79e95ecd84f3c261d2f6be8737fed272737325",
  });
  const LATITUDE = 29.9417666;
  const LONGITUDE = -95.3991524;
  const LATITUDE_DELTA = 0.0922;
  const LONGITUDE_DELTA = 0.0421;
  const SPACE = 0.01;
  const mapRef = useRef();
  const [mapCenter, setMapCenter] = useState({
    lat: 18.4861,
    lng: -69.9312,
  });
  const [region, setRegion] = useState({
    latitude: 18.4861,
    longitude: -69.9312,
    latitudeDelta: 0.0922,
    longitudeDelta: 0.0421,
  });
  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
  };

  const textStyle = {
    color: " #EB2830",
    fontFamily: "var(--font-gotham-black)",
    fontSize: " 22px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "22px",
    margin: width < 768 && "0",
  };
  const smallText = {
    color: " var(--Dark_Grey, #32343E)",
    fontFamily: "var(--font-gotham)",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "900",
    margin: "0 0 0 10px",
    lineHeight: "normal",
  };
  const btnStyle = {
    width: "353px",
    height: "57px",
    borderRadius: "100px",
    background: "#EB252D",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    // marginBotton:"px"
  };
  const btnStyle2 = {
    width: "100%",
    fontFamily: "var(--font-gotham)",
    borderRadius: "100px",
    background: "#EB252D",
    outline: "none",
    border: "none",
    color: "white",
    fontWeight: "900",
    padding: "20px 0",
  };

  const getLocationLL = async (address) => {
    try {
      const response = await Geocode.fromAddress(address.address);
      const { results, status } = response;
      if (status === "OK") {
        const { lat, lng } = results[0].geometry.location;
        const newRegion = {
          latitude: lat,
          longitude: lng,
          latitudeDelta: LATITUDE_DELTA,
          longitudeDelta: LATITUDE_DELTA,
        };
        setMapCenter({ lat, lng });
        setRegion(newRegion);
        mapRef.current.panTo({ lat, lng });
        mapRef.current.setZoom(13);
      } else {
        console.warn("Geocode API request failed with status:", status);
      }
      console.log(response);
    } catch (error) {
      console.error("Error fetching location:", error);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const onPayWithAzul = () => {
    const paymentData = localStorage.getItem("order_details");
    const total = JSON.parse(paymentData).total;
    const queryParams = new URLSearchParams({
      total,
      orderId: 0,
      publicUrl: process.env.REACT_APP_URL,
    });
    window.location.href = `${process.env.REACT_APP_URL}/payment.html?${queryParams}`;
  };

  console.log(branches);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        className="dialog"
        PaperProps={{
          style: {
            borderRadius: "28px",
            background: "var(--white-100, #FFF)",
            boxShadow: "0px 2px 10px 0px rgba(0, 0, 0, 0.25)",
            width: "100%",
          },
        }}
      >
        {next === 0 ? (
          <>
            {/* <img className='img-fluid' style={{ cursor: "pointer" }} onClick={handleClose} width={27} height={27} src={Cross} alt="cross" /> */}
            <DialogTitle
              className="d-flex justify-content-end cursor-pointer"
              id="responsive-dialog-title"
            >
              <LoadScript googleMapsApiKey="AIzaSyABy0de4oAU34qkNVvF4xiiVmvS9zdeiMY">
                <GoogleMap
                  ref={mapRef}
                  mapContainerStyle={{ height: "400px", width: "100%" }}
                  center={mapCenter}
                  zoom={13}
                  region={region}
                  initialRegion={{
                    latitude: 18.4861,
                    longitude: -69.9312,
                    latitudeDelta: 0.0922,
                    longitudeDelta: 0.0421,
                  }}
                >
                  {branches?.map((branch) => (
                    <>
                      <Marker
                        onPress={() => {
                          getLocationLL(branch);
                        }}
                        coordinate={{
                          latitude: Number(branch?.latitude),
                          longitude: Number(branch?.longitude),
                        }}
                        title={branch?.address}
                        key={branch?.id}
                        icon={
                          branchAddress?.id === branch.id ? null : logoImage
                        }
                        position={{
                          lat: Number(branch?.latitude),
                          lng: Number(branch?.longitude),
                        }}
                        onClick={() => setBranchAddress(branch)}
                      />
                    </>
                  ))}
                </GoogleMap>
              </LoadScript>
            </DialogTitle>
            <DialogContentText
              style={{ padding: width < 768 && "30px", marginBottom: 30 }}
            >
              <div style={{ padding: "0 30px" }}>
                <div style={textStyle}>Para retirar</div>
                {branchAddress?.name}
                {/* {restaurants.map((restaurant) => {
                                    return (
                                        <div className='d-flex mb-3 mt-3'>
                                            <div><img src={Location} /></div>
                                            <div style={{ ...smallText, color: address.id === restaurant.id ? "red" : "grey" }}>{restaurant.description}</div>
                                        </div>
                                    )
                                })} */}
              </div>
            </DialogContentText>
            <DialogActions className="d-flex justify-content-center mb-3">
              <div style={btnStyle}>
                <div>
                  <img width={29} height={29} src={LightLocation} />
                </div>
                <div
                  onClick={() => setNext(1)}
                  style={{
                    color: "var(--white-100, #FFF)",
                    fontFamily: "var(--font-gotham )",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "700",
                    lineHeight: "normal",
                  }}
                >
                  Guardar ubicación 
                </div>
              </div>
            </DialogActions>
          </>
        ) : next === 1 ? (
          <DialogTitle
            id="responsive-dialog-title"
            style={{ padding: "0", position: "relative" }}
          >
            <div>
              <div className="chat-bg">
                <p
                  style={{
                    fontSize: "22px",
                    fontWeight: "900",
                    fontFamily: "var(--font-gotham)",
                    padding: "44px 0 0 62px",
                    color: "#EB252D",
                  }}
                >
                  Programar pedido
                </p>
              </div>
              <div style={{ position: "absolute", top: "20px", right: "20px" }}>
                <img
                  className="img-fluid"
                  style={{ cursor: "pointer" }}
                  onClick={handleClose}
                  width={27}
                  height={27}
                  src={Cross}
                  alt="cross"
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Calendar
                onChange={(e) =>
                  setTime({ ...time, date: new Date(e).toLocaleDateString() })
                }
                value={time?.date}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "50px 0 0 0",
              }}
            >
              <BasicTimePicker setTime={setTime} time={time} />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "50px 0",
              }}
            >
              <button
                onClick={() => setNext(2)}
                style={{
                  width: "353px",
                  height: "59px",
                  backgroundColor: "#EB252D",
                  border: "none",
                  fontWeight: "900",
                  fontFamily: "var(--font-gotham)",
                  color: "#FFF",
                  borderRadius: "30px",
                }}
              >
                Aceptar
              </button>
            </div>
          </DialogTitle>
        ) : next === 2 ? (
          <>
            <DialogTitle
              id="responsive-dialog-title"
              style={{ padding: "0px", position: "relative", height: "80vh" }}
            >
              <div className="chat-bg">
                <p
                  style={{
                    color: "#EB252D",
                    fontFamily: "var(--font-gotham )",
                    fontSize: "22px",
                    fontStyle: "normal",
                    fontWeight: "900",
                    lineHeight: "normal",
                    padding: "50px 80px",
                  }}
                >
                  Métodos de pago
                </p>
              </div>
              <button
                style={{
                  margin: "10px 10px",
                  width: "97%",
                  padding: "10px 10px",
                  border: "1px solid #E52823",
                  borderRadius: "20px",
                }}
                onClick={onPayWithAzul}
              >
                <p style={{ marginBottom: "0px" }}></p>
                <div
                  style={{
                    color: "var(--Dark_Grey, #32343E)",
                    fontFamily: "var(--font-gotham-black)",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "700",
                    lineHeight: "normal",
                    // textTransform: 'uppercase',
                    margin: "5px 0",
                  }}
                >
                  Pay with Azul
                </div>
              </button>
            </DialogTitle>
          </>
        ) : (
          ""
        )}
      </Dialog>
    </>
  );
}

export default Modal;
