import React, { useEffect, useState } from 'react'
import Footer from '../components/Footer'
import FooterBanner from '../components/FooterBanner'
import Text from '../components/Text'
import Navbar from '../components/Navbar'
import Slick from '../components/Slick'
import BannerNav from '../components/BannerNav'
import Cart from '../components/Cart'
import { TotalBox } from '../components/TotalBox'
import MethodDelivery from '../components/MethodDelivery'
import backIcon from "../assets/chevron.png";
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { API_URL } from '../API/API_URL'
import { DotLoaderOverlay } from 'react-spinner-overlay'
import RelatedProducts from '../components/relatedProducts'
import emptyCart from "../assets/empty-cart.svg"

function Fourth() {

    const [cartItems, setCartItems] = useState([])
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const [width, setWidth] = useState()

    const token = localStorage.getItem("token")

    async function getCartItems() {
        setLoading(true)
        try {
            const response = await axios.get(`${API_URL}/cart`, {
                headers: {
                    Authorization: "Bearer " + token
                }
            })
            if (response.status === 200) {
                console.log("get cart items response", response);
                setLoading(false)
                setCartItems(response.data)
            }
        } catch (error) {
            setLoading(false)
            console.log("get cart items error", error);
        }
    }

    useEffect(() => {
        getCartItems()
    }, [])

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth)
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    console.log(cartItems);

    return (
        <>
            <DotLoaderOverlay color="red" loading={loading} />
            <div>
                <BannerNav text="Detalles de la orden" icon={backIcon} onClick={() => navigate(-1)} />
                <div className='container mt-5'>
                    <div className="row justify-content-between align-center">
                        {(cartItems?.items?.length === 0 || cartItems?.length === 0) ? (
                            <div className='col-lg-12 col-md-12 col-sm-12 mt-2'>
                                <div className='d-flex flex-column align-items-center justify-content-center'>
                                    <img className='img-fluid' src={emptyCart} />
                                    <p style={{
                                        color: '#E52823',
                                        fontFamily: 'var(--font-gotham)',
                                        fontSize: '35px',
                                        fontStyle: 'normal',
                                        fontWeight: '700',
                                        lineHeight: 'normal',
                                        textAlign: 'center',
                                        opacity: ' 0.7',
                                        margin: "100px 0 0 0",
                                    }}>El carrito esta vacío</p>
                                </div>
                            </div>
                        ) : (
                            <>
                                <div className='col-lg-6 col-md-12 col-sm-12 mt-2'>
                                    <Cart cartItems={cartItems} getCartItems={getCartItems} />
                                </div>
                                <div className='col-lg-4 col-md-12 col-sm-12 mt-2'>
                                    <div style={{ display: width < 1098 ? "flex" : "", justifyContent: width < 1098 ? "center" : "" }}>
                                        <TotalBox cartItems={cartItems} />
                                    </div>
                                    <div style={{ display: width < 1098 ? "flex" : "", justifyContent: width < 1098 ? "center" : "" }}>
                                        <MethodDelivery />
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <Text text="Articulos relacionados" font="35px" color={"#32343E"} />
                <br />
                {/* <Slick /> */}
                <RelatedProducts />
                <br />
                <Text text="Ofertas" font="35px" color={"#32343E"} />
            </div>
        </>
    )
}

export default Fourth