import React, { useEffect, useRef, useState } from 'react';
import { GoogleMap, LoadScript, Marker, DirectionsService, Polyline } from '@react-google-maps/api';
import { API_URL } from '../API/API_URL';
import axios from 'axios';
import logoImage2 from '../assets/delivery.svg';

const OrderTracker = ({ trackOrder }) => {
    const token = localStorage.getItem("token");
    const mapRef = useRef(null);
    const [userLocation, setUserLocation] = useState(null);
    const [riderLocation, setRiderLocation] = useState(null);
    const [directions, setDirections] = useState(null);
    const [polylinePath, setPolylinePath] = useState([]);

    useEffect(() => {
        if (trackOrder) {
            const geocoder = new window.google.maps.Geocoder();
            geocoder.geocode({ address: trackOrder?.user_address }, (results, status) => {
                if (status === 'OK' && results[0]) {
                    const location = results[0].geometry.location;
                    setUserLocation({ lat: location.lat(), lng: location.lng() });
                } else {
                    console.error('Geocode was not successful for the following reason:', status);
                }
            });
        }
    }, [trackOrder]);

    useEffect(() => {
        let intervalId;
        const fetchRiderLocation = async () => {
            try {
                const response = await axios.get(`${API_URL}/get-rider-current-location`, {
                    params: {
                        driver_id: 27,
                        order_id: 129,
                    },
                    headers: {
                        Authorization: "Bearer " + token
                    }
                });
                setRiderLocation({ lat: Number(response.data.latitude), lng: Number(response.data.longitude) });
            } catch (error) {
                console.error('Error fetching rider location:', error);
            }
        };
        intervalId = setInterval(fetchRiderLocation, 5000);
        return () => clearInterval(intervalId);
    }, [token]);

    useEffect(() => {
        if (userLocation && riderLocation) {
            const directionsService = new window.google.maps.DirectionsService();
            directionsService.route(
                {
                    origin: userLocation,
                    destination: riderLocation,
                    travelMode: 'DRIVING'
                },
                (response, status) => {
                    if (status === 'OK') {
                        setDirections(response);
                        const route = response.routes[0].overview_path.map(point => ({
                            lat: point.lat(),
                            lng: point.lng()
                        }));
                        setPolylinePath(route);
                    } else {
                        console.error('Directions request failed with status:', status);
                    }
                }
            );
        }
    }, [userLocation, riderLocation]);

    console.log({ riderLocation, userLocation });

    return (
        <LoadScript googleMapsApiKey="AIzaSyABy0de4oAU34qkNVvF4xiiVmvS9zdeiMY">
            <GoogleMap
                mapContainerStyle={{ height: '400px', width: '100%' }}
                center={userLocation || riderLocation}
                zoom={userLocation ? 13 : 8}
            >
                {userLocation && riderLocation && (
                    <>
                        <Marker position={userLocation} />
                        <Marker
                            icon={{ url: logoImage2, scaledSize: new window.google.maps.Size(40, 40) }}
                            position={riderLocation}
                        />
                        <Polyline
                            path={polylinePath}
                            options={{
                                strokeColor: '#FF0000',
                                strokeOpacity: 1,
                                strokeWeight: 4
                            }}
                        />
                    </>
                )}
            </GoogleMap>
        </LoadScript>
    );
};

export default OrderTracker;
