import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Banner from "../components/Banner";
import DummyCard from "../components/DummyCard";
import Text from "../components/Text";
import PortfolioGrid from "../components/PortfolioGrid";
import ProductSlider from "../components/ProductSlider";
import Products from "../components/Products";
import FooterBanner from "../components/FooterBanner";
import Footer from "../components/Footer";
import axios from "axios";
import { API_URL } from "../API/API_URL";
import { DotLoaderOverlay } from "react-spinner-overlay";
import Carousel from "../components/Carousel";

function Home() {
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState(null);
  const [categoryId, setCategoryId] = useState(1);
  const [items, setItems] = useState(null);
  const token = localStorage.getItem("token");
  console.log(token);

  const getCategory = async () => {
    try {
      const response = await axios.get(`${API_URL}/categories`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      if (response.status === 200) {
        console.log(response);
        setCategories(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCategory();
  }, []);

  async function getProductByCategory() {
    setLoading(true);
    try {
      const response = await axios.get(
        `${API_URL}/product-by-category?category_id=${categoryId}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      if (response.status === 200) {
        console.log(response);
        setLoading(false);
        setItems(response.data);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (categoryId !== null) {
      getProductByCategory();
    }
  }, [categoryId]);

  async function likeRelatedProduct(item) {
    setLoading(true);
    try {
      const response = await axios.post(
        `${API_URL}/like-unlike-product`,
        {
          product_id: item.id,
          like: !item.like,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      if (response.status === 200) {
        setLoading(false);
        console.log("response", response);
        getProductByCategory();
      }
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  }

  return (
    <>
      <DotLoaderOverlay color="red" loading={loading} />
      {/* <Banner items={items} /> */}
      <Carousel />
      <DummyCard />
      {/* <Text font="45px" color="#32343E" text="Mas vendido" /> */}
      <p
        style={{
          fontSize: "45px",
          fontWeight: "800",
          color: "#32343E",
          margin: "60px 0 39px 0",
          textAlign: "center",
        }}
      >
        Mas vendido
      </p>
      <PortfolioGrid items={items} />
      <br />
      <br />
      <ProductSlider
        categories={categories}
        setCategoryId={setCategoryId}
        categoryId={categoryId}
      />
      <Text font="45px" color="#E52823" text="Pollo Frito" />
      <Products
        items={items}
        setLoading={setLoading}
        likeRelatedProduct={likeRelatedProduct}
        getProductByCategory={getProductByCategory}
      />
      <div className="addMore">
        <div className="addMore_text">Ver todos los productos</div>
      </div>
      <Text font="48px" color="#32343E" text="Ofertas" />
    </>
  );
}

export default Home;
