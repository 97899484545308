import { useState, useRef, useEffect } from "react";
import Home from "../assets/Home.png";
import Edit from "../assets/edit.png";
import Mastercard from "../assets/Mastercard.png";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import LightLocation from "../assets/location-point.svg";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import Geocode from "react-geocode";
import Location from "../assets/location.png";
import visa from "../assets/visa-icon.svg";
import chevronDown from "../assets/chevron-down-2.svg";
import masterCard from "../assets/master-card.svg";
import add from "../assets/addCircle.svg";
import Cross from "../assets/cut.png";

function CustBox(props) {
  const {
    userLocations,
    address,
    setAddress,
    selectedLocation,
    setSelectedLocation,
    openEditModal,
    setOpenEditModal,
    openEditModal2,
    setOpenEditModal2,
    paymentType,
    setPaymentType,
  } = props;

  const [width, setWidth] = useState();

  const [selectedAddress, setSelectedAddress] = useState("");
  const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });
  const [mapZoom, setMapZoom] = useState(8);
  const [map, setMap] = useState(null);

  // useEffect(() => {
  //     const options = {
  //         enableHighAccuracy: true,
  //         timeout: 5000,
  //         maximumAge: 0
  //     };

  //     if (navigator.geolocation) {
  //         navigator.geolocation.getCurrentPosition(
  //             (position) => {
  //                 const { latitude, longitude } = position.coords;
  //                 const currentLocation = { lat: latitude, lng: longitude };

  //                 setSelectedLocation(currentLocation);
  //                 setMapCenter(currentLocation);
  //                 setMapZoom(15)
  //             },
  //             (error) => {
  //                 console.error('Error getting current location:', error);
  //             },
  //             options
  //         );
  //     } else {
  //         console.error('Geolocation is not supported by this browser.');
  //     }
  // }, []);

  useEffect(() => {
    // Set Santo Domingo coordinates as the initial map center
    setMapCenter({ lat: 18.4861, lng: -69.9312 });
    setMapZoom(13); // Set an appropriate zoom level
  }, []);

  const handleMapLoad = (map) => {
    setMap(map);
  };

  const handleMapClick = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();

    setSelectedLocation({ lat, lng });
    setMapCenter({ lat, lng });

    // Perform reverse geocoding to get address
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: { lat, lng } }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
          setSelectedAddress(results[0].formatted_address);
          const placeId = results[0].place_id;
          console.log("Place ID:", placeId);
          setAddress(results[0].formatted_address);
        } else {
          setAddress("Address not found");
        }
      } else {
        setAddress("Geocoder failed due to: " + status);
      }
    });
  };

  const onPayWithAzul = () => {
    const paymentData = localStorage.getItem("order_details");
    const total = JSON.parse(paymentData).total;
    const queryParams = new URLSearchParams({ total, orderId: 0 });
    window.location.href = `${process.env.REACT_APP_URL}/payment.html?${queryParams}`;
  };

  const main = {
    width: "350px",
    height: "101px",
    borderRadius: "20px",
    border: "1px solid var(--Basic_Red, #E52823)",
    background: "var(--Light_Blue, #F0F5FA)",
    display: "flex",
    justifyContent: "space-around",
    margin: "15px 0",
    padding: "10px 15px",
    cursor: "pointer",
  };
  const head = {
    color: "var(--Dark_Grey, #32343E)",
    fontFamily: "var(--font-gotham-black)",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
    // textTransform: 'uppercase',
    margin: "5px 0",
  };
  const smallText = {
    color: "var(--Dark_Grey, #32343E)",
    fontFamily: "var(--font-gotham-black)",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "14px",
    // width: "233px",
    opacity: "0.5",
    margin: "5px 10px",
    /* 100% */
  };
  const number = {
    color: "var(--Dark_Grey, #32343E)",
    fontFamily: "var(--font-gotham-black)",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
  };

  const textStyle = {
    color: " #EB2830",
    fontFamily: "var(--font-gotham-black)",
    fontSize: " 22px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "22px",
    margin: width < 768 && "0",
  };
  const btnStyle = {
    width: "353px",
    height: "57px",
    borderRadius: "100px",
    background: "#EB252D",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    // marginBotton:"px"
  };

  const payWithAzul = {};

  const [paymentForm, setPaymentForm] = useState({
    MerchantId: "39038540035",
    MerchantName: "Prueba AZUL",
    MerchantType: "E-Commerce",
    CurrencyCode: "$",
    OrderNumber: "001",
    Amount: "10000",
    ITBIS: "000",
    ApprovedUrl: "https://google.com/",
    DeclinedUrl: "https://google.com/",
    CancelUrl: "https://google.com/",
    UseCustomField1: "0",
    CustomField1Label: "",
    CustomField1Value: "",
    UseCustomField2: "0",
    CustomField2Label: "",
    CustomField2Value: "",
    AltMerchantName: "prueba",
    AuthHash:
      "86e90475e6dc7c6d6eb98e549bb97cb8aca25beeb44b967c5d4c8d95be91c08d0e9847b1a51a3321e8a6ead72a79e95ecd84f3c261d2f6be8737fed272737325",
  });

  const btnStyle2 = {
    width: "100%",
    fontFamily: "var(--font-gotham)",
    borderRadius: "100px",
    background: "#EB252D",
    outline: "none",
    border: "none",
    color: "white",
    fontWeight: "900",
    padding: "20px 0",
  };

  return (
    <>
      <Dialog
        open={openEditModal}
        onClose={() => setOpenEditModal(false)}
        aria-labelledby="responsive-dialog-title"
        className="dialog"
        PaperProps={{
          style: {
            borderRadius: "28px",
            background: "var(--white-100, #FFF)",
            boxShadow: "0px 2px 10px 0px rgba(0, 0, 0, 0.25)",
            width: "100%",
          },
        }}
      >
        <DialogTitle
          className="d-flex justify-content-end cursor-pointer"
          id="responsive-dialog-title"
        >
          <LoadScript googleMapsApiKey="AIzaSyABy0de4oAU34qkNVvF4xiiVmvS9zdeiMY">
            <GoogleMap
              mapContainerStyle={{ width: "100%", height: "400px" }}
              zoom={mapZoom}
              center={mapCenter}
              onLoad={handleMapLoad}
              onClick={handleMapClick}
            >
              {selectedLocation && <Marker position={selectedLocation} />}
            </GoogleMap>
          </LoadScript>
        </DialogTitle>
        <DialogContentText
          style={{ padding: width < 768 && "30px", marginBottom: 30 }}
        >
          <div style={{ padding: "0 30px" }}>
            <div style={textStyle}>Para retirar</div>
            {userLocations?.map((location) => {
              return (
                <div className="d-flex mb-3 mt-3">
                  <div>
                    <img src={Location} />
                  </div>
                  <p
                    style={{
                      ...smallText,
                      color: address?.id === location.id ? "red" : "grey",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setAddress(location);
                      const geocoder = new window.google.maps.Geocoder();
                      geocoder.geocode(
                        { address: location.direction },
                        (results, status) => {
                          if (status === "OK") {
                            if (results[0]) {
                              const location = results[0].geometry.location;
                              setSelectedLocation({
                                lat: location.lat(),
                                lng: location.lng(),
                              });
                              setMapCenter({
                                lat: location.lat(),
                                lng: location.lng(),
                              });
                            } else {
                              console.error("No results found");
                            }
                          } else {
                            console.error(
                              "Geocode was not successful for the following reason: " +
                                status
                            );
                          }
                        }
                      );
                    }}
                  >
                    {location?.direction}
                  </p>
                </div>
              );
            })}
          </div>
        </DialogContentText>
        <DialogActions className="d-flex justify-content-center mb-3">
          <div style={btnStyle} onClick={() => setOpenEditModal(false)}>
            <div>
              <img width={29} height={29} src={LightLocation} />
            </div>
            <div
              style={{
                color: "var(--white-100, #FFF)",
                fontFamily: "var(--font-gotham )",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "normal",
              }}
            >
              Guardar ubicación 
            </div>
          </div>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openEditModal2}
        onClose={() => setOpenEditModal2(false)}
        aria-labelledby="responsive-dialog-title"
        className="dialog"
        PaperProps={{
          style: {
            borderRadius: "28px",
            background: "var(--white-100, #FFF)",
            boxShadow: "0px 2px 10px 0px rgba(0, 0, 0, 0.25)",
            width: "100%",
          },
        }}
      >
        <DialogTitle
          id="responsive-dialog-title"
          style={{ padding: "0px", position: "relative", height: "80vh" }}
        >
          <div className="chat-bg">
            <p
              style={{
                color: "#EB252D",
                fontFamily: "var(--font-gotham )",
                fontSize: "22px",
                fontStyle: "normal",
                fontWeight: "900",
                lineHeight: "normal",
                padding: "50px 80px",
              }}
            >
              Métodos de pago
            </p>
          </div>
          <div style={{ position: "absolute", top: "20px", right: "20px" }}>
            <img
              className="img-fluid"
              style={{ cursor: "pointer" }}
              onClick={() => setOpenEditModal2(false)}
              width={27}
              height={27}
              src={Cross}
              alt="cross"
            />
          </div>
          <div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div
                style={{
                  textAlign: "center",
                  margin: "0 16px",
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    padding: "20px",
                    backgroundColor: "#F0F5FA",
                    borderRadius: "15px",
                  }}
                >
                  <img src={visa} />
                </div>
                <p>Visa</p>
              </div>
              <div
                style={{
                  textAlign: "center",
                  margin: "0 16px",
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    padding: "20px",
                    backgroundColor: "#F0F5FA",
                    borderRadius: "15px",
                  }}
                >
                  <img src={visa} />
                </div>
                <p>Mastercard</p>
              </div>
            </div>
            <div
              style={{
                padding: width < 768 ? "0 50px" : "0 100px",
                margin: "50px 0 0 0",
              }}
            >
              {/* <div style={{ margin: "0 0 30px 0" }} onClick={() => setPaymentType("Cash")}>
                                        <div style={{
                                            padding: "20px",
                                            backgroundColor: "#F0F5FA",
                                            borderRadius: "15px",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            cursor: "pointer",
                                            border: paymentType === "Cash" ? "2px solid red" : ""
                                        }}>
                                            <div>
                                                <p style={{ margin: 0 }}>Cash</p>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <img src={masterCard} />
                                                    <p style={{ margin: "0 10px" }}>***********</p>
                                                    <p style={{ margin: 0 }}>436</p>
                                                </div>
                                            </div>
                                            <div>
                                                <img src={chevronDown} />
                                            </div>
                                        </div>
                                    </div> */}
              <div onClick={() => setPaymentType("Mastercard")}>
                <div
                  style={{
                    padding: "20px",
                    backgroundColor: "#F0F5FA",
                    borderRadius: "15px",
                    display: "flex",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    border: paymentType === "Mastercard" ? "2px solid red" : "",
                  }}
                >
                  <div>
                    <p style={{ margin: 0 }}>Mastercard</p>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img src={masterCard} />
                      <p style={{ margin: "0 10px" }}>***********</p>
                      <p style={{ margin: 0 }}>332</p>
                    </div>
                  </div>
                  <div>
                    <img src={chevronDown} />
                  </div>
                </div>
              </div>
            </div>
            <div style={{ padding: width < 768 ? "0 50px" : "0 100px" }}>
              <div style={{ textAlign: "center", margin: "70px 0 26px 0" }}>
                <form
                  action="https://pruebas.azul.com.do/PaymentPage/"
                  method="post"
                  id="paymentForm"
                  name="paymentForm"
                >
                  <input
                    type="hidden"
                    id="MerchantId"
                    name="MerchantId"
                    defaultValue={paymentForm.MerchantId}
                  />
                  <input
                    type="hidden"
                    id="MerchantName"
                    name="MerchantName"
                    defaultValue={paymentForm.MerchantName}
                  />
                  <input
                    type="hidden"
                    id="MerchantType"
                    name="MerchantType"
                    defaultValue={paymentForm.MerchantType}
                  />
                  <input
                    type="hidden"
                    id="CurrencyCode"
                    name="CurrencyCode"
                    defaultValue={paymentForm.CurrencyCode}
                  />
                  <input
                    type="hidden"
                    id="OrderNumber"
                    name="OrderNumber"
                    defaultValue={paymentForm.OrderNumber}
                  />
                  <input
                    type="hidden"
                    id="Amount"
                    name="Amount"
                    defaultValue={paymentForm.Amount}
                  />
                  <input
                    type="hidden"
                    id="ITBIS"
                    name="ITBIS"
                    defaultValue={paymentForm.ITBIS}
                  />
                  <input
                    type="hidden"
                    id="ApprovedUrl"
                    name="ApprovedUrl"
                    defaultValue={paymentForm.ApprovedUrl}
                  />
                  <input
                    type="hidden"
                    id="DeclinedUrl"
                    name="DeclinedUrl"
                    defaultValue={paymentForm.DeclinedUrl}
                  />
                  <input
                    type="hidden"
                    id="CancelUrl"
                    name="CancelUrl"
                    defaultValue={paymentForm.CancelUrl}
                  />
                  <input
                    type="hidden"
                    id="UseCustomField1"
                    name="UseCustomField1"
                    defaultValue={paymentForm.UseCustomField1}
                  />
                  <input
                    type="hidden"
                    id="CustomField1Label"
                    name="CustomField1Label"
                    defaultValue={paymentForm.CustomField1Label}
                  />
                  <input
                    type="hidden"
                    id="CustomField1Value"
                    name="CustomField1Value"
                    defaultValue={paymentForm.CustomField1Value}
                  />
                  <input
                    type="hidden"
                    id="UseCustomField2"
                    name="UseCustomField2"
                    defaultValue={paymentForm.UseCustomField2}
                  />
                  <input
                    type="hidden"
                    id="CustomField2Label"
                    name="CustomField2Label"
                    defaultValue={paymentForm.CustomField2Label}
                  />
                  <input
                    type="hidden"
                    id="CustomField2Value"
                    name="CustomField2Value"
                    defaultValue={paymentForm.CustomField2Value}
                  />
                  <input
                    type="hidden"
                    id="AltMerchantName"
                    name="AltMerchantName"
                    defaultValue={paymentForm.AltMerchantName}
                  />
                  <input
                    type="hidden"
                    id="AuthHash"
                    name="AuthHash"
                    defaultValue={paymentForm.AuthHash}
                  />
                  <button type="submit" style={btnStyle2}>
                    Proceder al pago
                  </button>
                </form>
              </div>
              <div
                style={{
                  textAlign: "center",
                  margin: "0 0 0 0",
                  cursor: "pointer",
                }}
              >
                <p>
                  {" "}
                  <img src={add} /> Agregar Método de Pago
                </p>
              </div>
            </div>
          </div>
        </DialogTitle>
      </Dialog>

      <div style={main} onClick={() => setOpenEditModal(true)}>
        <div>
          <img src={Home} />
        </div>
        <div>
          <div className="mx-2" style={head}>
            CASA
          </div>
          <div style={smallText}>
            {address?.direction ? address?.direction : "seleccionar ubicación"}
          </div>
        </div>
        <div>
          <img src={Edit} />
        </div>
      </div>
      {/* <button
        style={{
          width: "350px",
          border: "1px solid #E52823",
          borderRadius: "20px",
          padding: "10px",
        }}
        onClick={onPayWithAzul}
      >
        <p style={{ marginBottom: "0px" }}></p>
        <div style={head}>Pay with Azul</div>
      </button> */}
      {/* <div style={main} onClick={() => setOpenEditModal2(true)}>
                <div>
                    <div style={head}>
                        Mastercard
                    </div>
                    <div className='d-flex '>
                        <div style={{ width: "29.7px", height: "21.7px" }}>
                            <img className='img-fluid' src={Mastercard} />
                        </div>
                        <div style={smallText}>
                            **** **** **** <span style={number}> 332</span>
                        </div>
                    </div>
                </div>
                <div>
                    <img src={Edit} />
                </div>
            </div> */}
    </>
  );
}

export default CustBox;
