import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme, } from '@mui/material/styles';
import Cross from "../assets/cut.png"
import Visa from "../assets/visa.png"
import Email from "../assets/Email.png"
import Mastercard from "../assets/Mastercard.png"
import Plus from "../assets/plusCircle.png"
import Right from "../assets/circleright.png"
import Eye from "../assets/eye.png"
// import { makeStyles } from '@material-ui/core';

import Map from "../assets/map.png"
import FB from "../assets/fb.png"
import Google from "../assets/new_gogle.png"
import BannerShort from "../assets/banner_Short.png"
import BannerShort2 from "../assets/map2.png"
// import locationIcon from "../assets/location3.svg"
// import locationIcon2 from "../assets/location4.svg"
import Logo from "../assets/loginlogo.png"
import lock from "../assets/lock.svg"
import { HandleAsyncLogin } from '../middlewares/auth/auth';
import axios from 'axios';
import { DotLoader, RingSpinner } from 'react-spinner-overlay';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import GlobalContext from '../context';

function LoginModal({ value }) {

    const [modalType, setModalType] = React.useState("login")
    const [loading, setLoading] = React.useState(false)
    const [open, setOpen] = React.useState(value);
    const [showPassword, setShowPassword] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const { isAuth, setisAuth } = React.useContext(GlobalContext)

    const handleClickOpen = () => {
        setOpen(true);
    };

    React.useEffect(() => {
        setOpen(value)
    }, [value])

    const handleClose = () => {
        setOpen(false);
        setisAuth("")
    };

    const textStyle = {
        color: 'var(--Basic_Red, #E52823)',
        fontFamily: 'var(--font-gotham)',
        fontSize: '22px',
        fontStyle: 'normal',
        fontWeight: '900',
        lineHeight: 'normal',
        marginTop: "26px",
        marginBottom: "31px"

    }
    const inputStyle = {
        width: '308px',
        height: '57px',
        borderRadius: '100px',
        background: 'var(--Light_Blue, #F0F5FA)',
        border: "none",
        outline: "none",
        padding: "0 42px",
        color: ' #B5B5B5',
        fontFamily: 'var(--font-gotham)',
        fontSize: ' 14px',
        fontStyle: 'normal',
        fontWeight: '900',

        lineHeight: "normal"

    }
    const inputStyle1 = {
        width: '308px',
        height: '57px',
        borderRadius: '100px',
        background: 'var(--Light_Blue, #F0F5FA)',
        border: "none",
        outline: "none",
        padding: "0 30px",
        color: ' #B5B5B5',
        fontFamily: 'var(--font-gotham)',
        fontSize: ' 14px',
        fontStyle: 'normal',
        fontWeight: '900',

        lineHeight: "normal"

    }
    const smallText1 = {
        color: '#A5A5A5',
        fontFamily: 'var(--font-gotham)',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '900',
        lineHeight: "normal",
        margin: "0 8px",
        cursor: "pointer"
    }
    const smallText2 = {
        color: '#A5A5A5',
        fontFamily: 'var(--font-gotham)',
        fontSize: '24px',
        fontStyle: 'normal',
        fontWeight: '900',
        lineHeight: "normal",
        margin: "80px 8px 36px 8px",
        cursor: "pointer"
    }
    const btnStyle = {
        width: "308px",
        backgroundColor: 'var(--basic-red)',
        // padding: "19px",
        height: "53px",
        borderRadius: "100px",
        // textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#fff",
        cursor: "pointer",
        margin: "15px"
    }
    const disableBtn = {
        width: "308px",
        backgroundColor: '#F0F5FA',
        // padding: "19px",
        height: "53px",
        borderRadius: "100px",
        // textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "black",
        cursor: "pointer",
        margin: "15px"
    }
    const boxStyle = {
        width: '160px',
        height: '57px',
        background: 'var(--white-100, #FFF)',
        borderRadius: '28.5px',
        border: '1.239px solid var(--Basic_Red, #E52823)',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "19px 5px",
        cursor: "pointer"
    }
    const boxStyle1 = {
        width: '327px',
        height: '82px',
        background: 'var(--Light_Blue, #F0F5FA)',
        // border: "2px solid var(--Basic_Red, #E52823)",
        borderRadius: '15px',
        padding: "13px 27px"

    }
    const text = {
        color: '#000',
        fontFamily: 'var(--font-gotham)',
        fontSize: '13px',
        fontStyle: 'normal',
        fontWeight: '900',
        lineHeight: 'normal',
        letterSpacing: '0.65px',
    }
    const redText = {
        color: 'var(--Basic_Red, #E52823)',
        fontFamily: 'var(--font-gotham)',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '900',
        lineHeight: 'normal',
        cursor: "pointer"
    }

    const [fields, setFields] = React.useState({
        email: "",
        password: "",
        name: "",
        last_name: "",
        email: "",
        ballot: "",
        telephone: "",
        address: "",
        password: "",
        password_confirmation: "",
        role: "user",
        device_token: "82409feind"
    });

    const [otp, setOtp] = React.useState(null)

    async function handleSubmit() {
        setLoading(true)
        try {
            const response = await axios.post("https://www.pv.slgrd.com/api/auth/login", {
                email: fields.email,
                password: fields.password,
            })
            if (response.status === 200) {
                console.log(response);
                localStorage.setItem("token", response?.data?.token)
                localStorage.setItem("currentUser", JSON.stringify(response?.data?.user))
                enqueueSnackbar("login successfully", {
                    variant: "success",
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }
                })
                setLoading(false)
                setFields({
                    email: "",
                    password: "",
                    name: "",
                    last_name: "",
                    email: "",
                    ballot: "",
                    telephone: "",
                    address: "",
                    password: "",
                    password_confirmation: "",
                    role: "user",
                    device_token: "82409feind"
                })
                setTimeout(() => {
                    window.location.reload()
                }, 2000);
            }
        } catch (error) {
            setLoading(false)
            enqueueSnackbar(error?.response?.data?.message, {
                variant: "error",
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            })
            console.log(error);
        }
    }

    async function handleSignup() {
        setLoading(true)
        try {
            const response = await axios.post("https://www.pv.slgrd.com/api/auth/register", {
                name: fields.name,
                last_name: fields.last_name,
                email: fields.email,
                ballot: fields.identification_card,
                telephone: fields.telephone,
                address: fields.address,
                password: fields.password,
                password_confirmation: fields.password_confirmation,
                role: "user",
                device_token: "129830182309",
            })
            if (response.status === 200) {
                console.log(response);
                setModalType("login")
                setFields({
                    email: "",
                    password: "",
                    name: "",
                    last_name: "",
                    email: "",
                    ballot: "",
                    telephone: "",
                    address: "",
                    password: "",
                    password_confirmation: "",
                    role: "user",
                    device_token: "82409feind"
                })
                setLoading(false)
                enqueueSnackbar("Account created successfully", {
                    variant: "success",
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }
                })
            }
        } catch (error) {
            setLoading(false)
            enqueueSnackbar(error.response.data.message, {
                variant: "error",
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            })
            console.log(error);
        }
    }

    async function handleForgetPassword() {
        setLoading(true)
        try {
            const response = await axios.post("https://www.pv.slgrd.com/api/auth/forget-password", {
                email: fields.email,
            })
            if (response.status === 200) {
                console.log(response);
                setModalType("otpModal")
                setLoading(false)
                enqueueSnackbar(response?.data?.message, {
                    variant: "success",
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }
                })
            }
        } catch (error) {
            setLoading(false)
            enqueueSnackbar(error?.response?.data?.message, {
                variant: "error",
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            })
            console.log(error);
        }
    }

    async function handleCheckOtp() {
        setLoading(true)
        try {
            const response = await axios.post("https://www.pv.slgrd.com/api/auth/check-otp", {
                email: fields.email,
                otp: otp
            })
            if (response.status === 200) {
                console.log(response);
                setModalType("newPasswordModal")
                setLoading(false)
                enqueueSnackbar(response?.data?.message, {
                    variant: "success",
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }
                })
            }
        } catch (error) {
            setLoading(false)
            enqueueSnackbar(error?.response?.data?.message, {
                variant: "error",
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            })
            console.log(error);
        }
    }

    async function handleChangePassword() {
        setLoading(true)
        try {
            const response = await axios.post("https://www.pv.slgrd.com/api/auth/change-password", {
                email: fields.email,
                password: fields.password,
                otp: otp,
            })
            if (response.status === 200) {
                console.log(response);
                setModalType("login")
                setLoading(false)
                enqueueSnackbar(response?.data?.message, {
                    variant: "success",
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }
                })
            }
        } catch (error) {
            setLoading(false)
            enqueueSnackbar(error?.response?.data?.message, {
                variant: "error",
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            })
            console.log(error);
        }
    }

    console.log(fields);

    return (
        <React.Fragment>
            <SnackbarProvider />
            {modalType === "login" ? (
                <Dialog

                    open={open}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                    className='dialog'
                    PaperProps={{
                        style: {
                            borderRadius: "28px",
                            background: 'var(--white-100, #FFF)',
                            boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.25)',
                            height: "100vh"
                        },
                    }}
                >

                    <DialogTitle id="responsive-dialog-title" style={{ padding: "0px", position: 'relative', height: "80vh" }}>
                        <div>
                            <img className='img-fluid' src={BannerShort} />
                        </div>
                        <div style={{ position: 'absolute', top: "20px", right: "20px" }}>
                            <img className='img-fluid' style={{ cursor: "pointer" }} onClick={handleClose} width={27} height={27} src={Cross} alt="cross" />

                        </div>
                        <div style={{ marginTop: "-75px", textAlign: "center" }}>
                            <img width={137} height={184} className='img-fluid' src={Logo} alt='' />
                        </div>
                        <div className='d-flex justify-content-center flex-column align-items-center'>

                            <div style={textStyle}>INICIO</div>

                            <div style={{ position: "relative", margin: "20px 0" }} >
                                <div >
                                    <input value={fields.email} onChange={(e) => {
                                        setFields((prevValue) => {
                                            return {
                                                ...prevValue,
                                                email: e.target.value
                                            }
                                        })
                                    }} type='text' placeholder='Correo electronico*' style={inputStyle} />
                                </div>
                                <div style={{ position: "absolute", top: "12px", left: "17px" }}><img width={18} height={14} src={Email} /></div>
                            </div>

                            <div style={{ position: "relative", margin: "10px 0" }} >
                                <div>
                                    <input value={fields.password} onChange={(e) => {
                                        setFields((prevValue) => {
                                            return {
                                                ...prevValue,
                                                password: e.target.value
                                            }
                                        })
                                    }} type={showPassword === true ? "text" : "password"} placeholder='Contraseña*' style={inputStyle1} />
                                </div>
                                <div style={{ position: "absolute", top: "12px", right: "17px" }} onClick={() => setShowPassword(!showPassword)}><img width={18} height={14} src={Eye} /></div>
                            </div>

                            <div onClick={handleSubmit} style={loading ? disableBtn : btnStyle}>{loading ? <DotLoader color='red' loading={loading} /> : "Ingresar"}</div>
                            <div style={smallText1} onClick={() => setModalType("forgetPassword")}>¿Has olvidado tu contraseña?</div>
                            <br />
                            <div className='d-flex align-items-center justify-content-center'>
                                <div style={{ width: "98px", height: "2px", background: 'var(--Basic_Red, #E52823)' }}></div>
                                <div style={smallText1}>Continuar con</div>
                                <div style={{ width: "102px", height: "2px", background: 'var(--Basic_Red, #E52823)' }}></div>

                            </div>

                            <div className='d-flex justify-content-around'>
                                <div style={boxStyle}>
                                    <div style={{ margin: "0 10px 0 0" }}><img width={31.23} height={30.23} src={Google} /></div>
                                    <div style={text}>
                                        GOOGLE
                                    </div>
                                </div>
                                <div style={boxStyle}>
                                    <div style={{ margin: "0 10px 0 0" }}><img width={31.23} height={30.23} src={FB} /></div>
                                    <div style={text}>
                                        FACEBOOK
                                    </div>
                                </div>
                            </div>
                            <div style={redText} onClick={() => setModalType("signup")}>Crear cuenta nueva</div>
                        </div>
                    </DialogTitle>
                    <br />
                </Dialog>
            ) : modalType === "signup" ? (
                <Dialog

                    open={open}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                    className='dialog'
                    PaperProps={{
                        style: {
                            borderRadius: "28px",
                            background: 'var(--white-100, #FFF)',
                            boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.25)',
                            height: "100vh"
                        },
                    }}
                >
                    <DialogTitle id="responsive-dialog-title" style={{ padding: "0px", position: 'relative', height: "80vh" }}>
                        <div>
                            <img className='img-fluid' src={BannerShort} />
                        </div>
                        <div style={{ position: 'absolute', top: "20px", right: "20px" }}>
                            <img className='img-fluid' style={{ cursor: "pointer" }} onClick={handleClose} width={27} height={27} src={Cross} alt="cross" />

                        </div>
                        <div style={{ marginTop: "-75px", textAlign: "center" }}>
                            <img width={137} height={184} className='img-fluid' src={Logo} alt='' />
                        </div>
                        <div className='d-flex justify-content-center flex-column align-items-center'>
                            <div style={{ ...smallText1, margin: "36px 0 0 0", fontSize: "24px" }}>Registrate</div>
                            <div style={{ position: "relative", margin: "10px 0 5px 0" }} >
                                <div>
                                    <input
                                        value={fields.name}
                                        onChange={(e) => {
                                            setFields((prevFields) => {
                                                return {
                                                    ...prevFields,
                                                    name: e.target.value
                                                }
                                            })
                                        }}
                                        type='text' placeholder='Nombre*' style={inputStyle} />
                                </div>
                            </div>
                            <div style={{ position: "relative", margin: "5px 0" }} >
                                <div >
                                    <input
                                        value={fields.last_name}
                                        onChange={(e) => {
                                            setFields((prevFields) => {
                                                return {
                                                    ...prevFields,
                                                    last_name: e.target.value
                                                }
                                            })
                                        }}
                                        type='text'
                                        placeholder='Apellido*'
                                        style={inputStyle1} />
                                </div>
                            </div>
                            <div style={{ position: "relative", margin: "5px 0" }} >
                                <div >
                                    <input
                                        value={fields.email}
                                        onChange={(e) => {
                                            setFields((prevFields) => {
                                                return {
                                                    ...prevFields,
                                                    email: e.target.value
                                                }
                                            })
                                        }}
                                        type='text'
                                        placeholder='correo electrónico'
                                        style={inputStyle1} />
                                </div>
                            </div>
                            <div style={{ position: "relative", margin: "5px 0" }} >
                                <div >
                                    <input
                                        value={fields.identification_card}
                                        onChange={(e) => {
                                            setFields((prevFields) => {
                                                return {
                                                    ...prevFields,
                                                    identification_card: e.target.value
                                                }
                                            })
                                        }}
                                        type='text'
                                        placeholder='Cédula*'
                                        style={inputStyle1} />
                                </div>
                            </div>
                            <div style={{ position: "relative", margin: "5px 0" }} >
                                <div >
                                    <input
                                        value={fields.telephone}
                                        onChange={(e) => {
                                            setFields((prevFields) => {
                                                return {
                                                    ...prevFields,
                                                    telephone: e.target.value
                                                }
                                            })
                                        }}
                                        type='text' placeholder='Teléfono*' style={inputStyle1} />
                                </div>
                            </div>
                            <div style={{ position: "relative", margin: "5px 0" }} >
                                <div >
                                    <input
                                        value={fields.address}
                                        onChange={(e) => {
                                            setFields((prevFields) => {
                                                return {
                                                    ...prevFields,
                                                    address: e.target.value
                                                }
                                            })
                                        }}
                                        type='text' placeholder='Dirección*' style={inputStyle1} />
                                </div>
                            </div>
                            <div style={{ position: "relative", margin: "5px 0" }} >
                                <div >
                                    <input
                                        value={fields.password}
                                        onChange={(e) => {
                                            setFields((prevFields) => {
                                                return {
                                                    ...prevFields,
                                                    password: e.target.value
                                                }
                                            })
                                        }}
                                        type={showPassword === true ? "text" : "password"} placeholder='Contraseña*' style={inputStyle1} />
                                </div>
                                <div style={{ position: "absolute", top: "12px", right: "17px" }} onClick={() => setShowPassword(!showPassword)}><img width={18} height={14} src={Eye} /></div>
                            </div>
                            <div style={{ position: "relative", margin: "5px 0" }} >
                                <div >
                                    <input
                                        value={fields.password_confirmation}
                                        onChange={(e) => {
                                            setFields((prevFields) => {
                                                return {
                                                    ...prevFields,
                                                    password_confirmation: e.target.value
                                                }
                                            })
                                        }}
                                        type={showPassword === true ? "text" : "password"} placeholder='Repetir contraseña*' style={inputStyle1} />
                                </div>
                                <div style={{ position: "absolute", top: "12px", right: "17px" }} onClick={() => setShowPassword(!showPassword)}><img width={18} height={14} src={Eye} /></div>

                            </div>
                            <div onClick={handleSignup} style={loading ? disableBtn : btnStyle}>{loading ? <DotLoader color='red' loading={loading} /> : "Registrarse"}</div>
                            <div style={smallText1} onClick={() => setModalType("login")}>Iniciar sesión</div>
                        </div>
                    </DialogTitle>
                    <br />
                </Dialog>
            ) : modalType === "otpModal" ? (
                <Dialog

                    open={open}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                    className='dialog'
                    PaperProps={{
                        style: {
                            borderRadius: "28px",
                            background: 'var(--white-100, #FFF)',
                            boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.25)',
                            height: "100vh"
                        },
                    }}
                >
                    <DialogTitle id="responsive-dialog-title" style={{ padding: "0px", position: 'relative', height: "80vh" }}>
                        <div>
                            <img className='img-fluid' src={BannerShort} />
                        </div>
                        <div style={{ position: 'absolute', top: "20px", right: "20px" }}>
                            <img className='img-fluid' style={{ cursor: "pointer" }} onClick={handleClose} width={27} height={27} src={Cross} alt="cross" />
                        </div>
                        <div style={{ marginTop: "-75px", textAlign: "center" }}>
                            <img width={137} height={184} className='img-fluid' src={Logo} alt='' />
                        </div>
                        <div className='d-flex justify-content-center flex-column align-items-center' style={{ marginTop: 60 }}>
                            <div style={{ position: "relative", margin: "20px 0" }} >
                                <div>
                                    <input maxLength={4} value={otp} onChange={(e) => setOtp(e.target.value)} type='text' placeholder='otp code' style={inputStyle} />
                                </div>
                                <div style={{ position: "absolute", top: "12px", left: "17px" }}><img width={18} height={14} src={Email} /></div>
                            </div>
                            <div onClick={handleCheckOtp} style={loading ? disableBtn : btnStyle}>
                                {loading ? <DotLoader color='red' loading={loading} /> : "Comprobar otp"}</div>
                        </div>
                    </DialogTitle>
                    <br />
                </Dialog>
            ) : modalType === "newPasswordModal" ? (
                <Dialog

                    open={open}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                    className='dialog'
                    PaperProps={{
                        style: {
                            borderRadius: "28px",
                            background: 'var(--white-100, #FFF)',
                            boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.25)',
                            height: "100vh"
                        },
                    }}
                >
                    <DialogTitle id="responsive-dialog-title" style={{ padding: "0px", position: 'relative', height: "80vh" }}>
                        <div>
                            <img className='img-fluid' src={BannerShort} />
                        </div>
                        <div style={{ position: 'absolute', top: "20px", right: "20px" }}>
                            <img className='img-fluid' style={{ cursor: "pointer" }} onClick={handleClose} width={27} height={27} src={Cross} alt="cross" />
                        </div>
                        <div style={{ marginTop: "-75px", textAlign: "center" }}>
                            <img width={137} height={184} className='img-fluid' src={Logo} alt='' />
                        </div>
                        <div className='d-flex justify-content-center flex-column align-items-center'>
                            <div style={smallText2} onClick={() => setModalType("login")}>establecer nueva contraseña</div>
                            <div style={{ position: "relative", margin: "20px 0" }} >
                                <div>
                                    <input value={fields.password} onChange={(e) => setFields((prevFields) => ({ ...prevFields, password: e.target.value }))} type='text' placeholder='nueva contraseña*' style={inputStyle} />
                                </div>
                                <div style={{ position: "absolute", top: "12px", left: "17px" }}><img width={18} height={14} src={Email} /></div>
                            </div>
                            <div onClick={handleChangePassword} style={loading ? disableBtn : btnStyle}>
                                {loading ? <DotLoader color='red' loading={loading} /> : (
                                    <>
                                        <img src={lock} /> entregar
                                    </>
                                )}</div>

                        </div>
                    </DialogTitle>
                    <br />
                </Dialog>
            ) : (
                (
                    <Dialog

                        open={open}
                        onClose={handleClose}
                        aria-labelledby="responsive-dialog-title"
                        className='dialog'
                        PaperProps={{
                            style: {
                                borderRadius: "28px",
                                background: 'var(--white-100, #FFF)',
                                boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.25)',
                                height: "100vh"
                            },
                        }}
                    >
                        <DialogTitle id="responsive-dialog-title" style={{ padding: "0px", position: 'relative', height: "80vh" }}>
                            <div>
                                <img className='img-fluid' src={BannerShort} />
                            </div>
                            <div style={{ position: 'absolute', top: "20px", right: "20px" }}>
                                <img className='img-fluid' style={{ cursor: "pointer" }} onClick={handleClose} width={27} height={27} src={Cross} alt="cross" />
                            </div>
                            <div style={{ marginTop: "-75px", textAlign: "center" }}>
                                <img width={137} height={184} className='img-fluid' src={Logo} alt='' />
                            </div>
                            <div className='d-flex justify-content-center flex-column align-items-center'>
                                <div style={smallText2} onClick={() => setModalType("login")}>¿Has olvidado tu contraseña?</div>
                                <div style={{ position: "relative", margin: "20px 0" }} >
                                    <div>
                                        <input value={fields.email} onChange={(e) => setFields((prevFields) => ({ ...prevFields, email: e.target.value }))} type='text' placeholder='Correo electronico*' style={inputStyle} />
                                    </div>
                                    <div style={{ position: "absolute", top: "12px", left: "17px" }}><img width={18} height={14} src={Email} /></div>
                                </div>
                                <div onClick={handleForgetPassword} style={loading ? disableBtn : btnStyle}>
                                    {loading ? <DotLoader color='red' loading={loading} /> : (
                                        <>
                                            <img src={lock} /> Restablecer contraseña
                                        </>
                                    )}</div>

                            </div>
                        </DialogTitle>
                        <br />
                    </Dialog>
                )
            )}
            {/* <Dialog
                
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                id='my-dialog'
                PaperProps={{
                    style: {
                        borderRadius: "28px",
                        background: 'var(--white-100, #FFF)',
                        boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.25)',
                    },
                }}
            >
                <DialogTitle id="responsive-dialog-title" style={{ padding: "0", position: 'relative', height: "80vh" }}>
                    <div style={{ textAlign: "right", padding: "36px 41px 20px 41px" }}>
                        <img className='img-fluid' style={{ cursor: "pointer" }} onClick={handleClose} width={27} height={27} src={Cross} alt="cross" />
                    </div>
                    <div>
                        <img className='img-fluid' src={BannerShort2} />
                    </div>
                    <div className='d-flex justify-content-center flex-column align-items-center'>
                        <div>
                            <p style={textStyle}>Para retirar</p>
                            <div>
                                <p style={{
                                    color: "#32343E",
                                    fontSize: "14px",
                                    fontStyle: "normal",
                                    fontWeight: 900,
                                    margin: "6px 0",
                                    fontFamily: 'var(--font-gotham)',
                                }}> <img src={locationIcon} alt="" /> PLAZA CUADRA, San Isidro. Av. Rafael Tomas Fernández Dominguez, Santo Domingo este.</p>
                                <p style={{

                                    color: "#ADADAF",
                                    fontSize: "14px",
                                    fontStyle: "normal",
                                    fontWeight: 900,
                                    margin: "6px 0",
                                    fontFamily: 'var(--font-gotham)',
                                }}> <img src={locationIcon} alt="" /> Avn. John F. Kennedy esquina Tiradentes, estación de combustible Sunix</p>
                                <p style={{

                                    color: "#ADADAF",
                                    fontSize: "14px",
                                    fontStyle: "normal",
                                    fontWeight: 900,
                                    margin: "6px 0",
                                    fontFamily: 'var(--font-gotham)',
                                }}> <img src={locationIcon} alt="" /> Av. 27 de Febrero casi esq. Caonabo.</p>
                                <p style={{

                                    color: "#ADADAF",
                                    fontSize: "14px",
                                    fontStyle: "normal",
                                    fontWeight: 900,
                                    margin: "6px 0",
                                    fontFamily: 'var(--font-gotham)',
                                }}> <img src={locationIcon} alt="" /> Galería 360, Food Court.</p>
                                <p style={{

                                    color: "#ADADAF",
                                    fontSize: "14px",
                                    fontStyle: "normal",
                                    fontWeight: 900,
                                    margin: "6px 0",
                                    fontFamily: 'var(--font-gotham)',
                                }}> <img src={locationIcon} alt="" /> Jumbo Av. Luperón, Food Court.</p>
                                <p style={{

                                    color: "#ADADAF",
                                    fontSize: "14px",
                                    fontStyle: "normal",
                                    fontWeight: 900,
                                    margin: "6px 0",
                                    fontFamily: 'var(--font-gotham)',
                                }}> <img src={locationIcon} alt="" /> Av. Luperón, estación Total</p>
                            </div>
                        </div>
                        <div style={btnStyle}><img src={locationIcon2} /> Guardar ubicación</div>
                    </div>
                </DialogTitle>
                <br />
            </Dialog> */}
        </React.Fragment>
    );
}

export default LoginModal;