import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import BannerNav from '../components/BannerNav'
import Home from "../assets/Home.png"
import Edit from "../assets/edit.png"
import Mastercard from "../assets/Mastercard.png"
import Delete from "../assets/Delete2.svg"
import locationIcon from "../assets/Location.svg"
import Sidebar from '../components/Sidebar'
import settingIcon from '../assets/settings.svg';
import axios from 'axios'
import { API_URL } from '../API/API_URL'
import { DotLoaderOverlay } from 'react-spinner-overlay'
import { useNavigate } from 'react-router-dom'
import EditLocationModal from '../components/EditLocationModal'

function Ubicaciones1() {

    const [state, setState] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [selectedLocation, setSelectedLocation] = React.useState(false);
    const [editAddress, setEditAddress] = React.useState(false);
    const [width, setWidth] = useState()

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth)
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const main = {
        width: '351px',
        height: '102px',
        borderRadius: '20px',
        background: 'var(--Light_Blue, #F0F5FA)',
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        margin: "15px 0"
    }

    const head = {
        color: 'var(--Dark_Grey, #32343E)',
        fontFamily: 'var(--font-gotham-black)',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: 'normal',
        margin: "5px 0"
    }

    const smallText = {
        color: 'var(--Dark_Grey, #32343E)',
        fontFamily: 'var(--font-gotham-black)',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: '14px',
        width: "233px",
        opacity: '0.5',
        margin: "5px 10px"
    }

    const number = {
        color: 'var(--Dark_Grey, #32343E)',
        fontFamily: 'var(--font-gotham-black)',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: 'normal'
    }

    const delBox = {
        width: '66px',
        height: '66px',
        borderRadius: '100px',
        background: '#EB252D',
        boxShadow: ' 0px 2px 6px 0px rgba(0, 0, 0, 0.20)',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer"
    }

    const btnStyle = {
        width: '345px',
        height: '57px',
        borderRadius: '100px',
        background: '#EB252D',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: ' var(--white-100, #FFF)',
        fontFamily: 'var(--font-gotham)',
        fontSize: ' 16px',
        fontStyle: 'normal',
        fontWeight: '700',
        margin: "75px 0 0 0",
        cursor: "pointer"
    }

    const smallText1 = {
        color: '#EB252D',
        fontFamily: 'var(--font-gotham)',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '900',
        lineHeight: 'normal',
        margin: "20px 0",
        cursor: "pointer"
    }

    const token = localStorage.getItem("token")
    const [loading, setLoading] = useState(false)
    const [addresses, setAddresses] = useState([])

    async function getAddress() {
        setLoading(true)
        try {
            const res = await axios.get(`${API_URL}/address`, {
                headers: {
                    Authorization: "Bearer " + token
                }
            })
            if (res.status === 200) {
                console.log(res);
                setAddresses(res.data)
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            console.log(error);
        }
    }

    async function deleteAddress(id) {
        setLoading(true)
        try {
            const res = await axios.delete(`${API_URL}/address/${id}`, {
                headers: {
                    Authorization: "Bearer " + token
                }
            })
            if (res.status === 200) {
                console.log(res);
                getAddress()
            }
        } catch (error) {
            setLoading(false)
            console.log(error);
        }
    }

    useEffect(() => {
        getAddress()
    }, [])

    const navigate = useNavigate()

    const [address, setAddress] = React.useState({
        direction: "",
        street: "",
        additional: "",
        type: ""
    })

    console.log(address);

    return (
        <>
            <EditLocationModal
                address={address}
                setAddress={setAddress}
                open={open}
                setOpen={setOpen}
                selectedLocation={selectedLocation}
                setSelectedLocation={setSelectedLocation}
            />
            <DotLoaderOverlay color='red' loading={loading} />
            <div
                style={{
                    position: "relative",
                    cursor: "pointer"
                }}
            >
                <div
                    onClick={() => setState(true)}
                    style={{
                        position: "absolute",
                        backgroundColor: "red",
                        borderRadius: "100%",
                        width: "60px",
                        height: "60px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        top: 100,
                        right: 20,
                        zIndex: 1000
                    }}>
                    <img src={settingIcon} />
                </div>
            </div>
            <BannerNav text={'Ubicaciones guardadas'} icon={locationIcon} />
            <div className='d-flex justify-content-center flex-column align-items-center'>
                {addresses?.map((add, index) => {
                    return (
                        <div style={{
                            display: "flex",
                            alignItems: width < 768 ? "end" : "center",
                            flexDirection: width < 768 ? "column" : "row",
                            cursor: "pointer"
                        }}

                        >
                            <div style={main}>
                                <div>
                                    <img src={Home} />
                                </div>
                                <div>
                                    <div className='mx-2' style={head}>
                                        {add.type}
                                    </div>
                                    <div style={smallText}>{add.direction}</div>
                                </div>
                                <div onClick={() => {
                                    setOpen(true)
                                    setAddress({ additional: add.additional, direction: add.direction, type: add.type, street: add.street })
                                }} style={{ marginTop: "-50px" }}>
                                    <img src={Edit} />
                                </div>
                            </div>
                            <div className='mx-3' style={delBox} onClick={() => deleteAddress(add.id)}>
                                <img width={39} height={39} src={Delete} alt='' />
                            </div>
                        </div>
                    )
                })}
                <div onClick={() => navigate("/Ubicaciones2")} style={btnStyle}>Agregar ubicación </div>
                <div style={smallText1}>Eliminar ubicación </div>
            </div>

            <Sidebar state={state} setState={setState} />
        </>
    )
}

export default Ubicaciones1