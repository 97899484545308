import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme, } from '@mui/material/styles';
import Cross from "../assets/cut.png"
import Map from "../assets/map.png"
import Text from './Text';
import Location from "../assets/location.png"
import LightLocation from "../assets/location-point.svg"
import rating from "../assets/rating.svg"
import BannerShort from "../assets/banner_Short.png"
import Logo from "../assets/loginlogo.png"
import visa from "../assets/visa-icon.svg"
import chevronDown from "../assets/chevron-down-2.svg"
import masterCard from "../assets/master-card.svg"
import add from "../assets/addCircle.svg"
import { useNavigate } from 'react-router-dom';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import BasicTimePicker from './TimePIcker';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

function EditLocationModal(props) {

    const {
        open,
        setOpen,
        editAddress,
        address,
        setAddress,
        selectedLocation,
        setSelectedLocation
    } = props

    const theme = useTheme();
    const [value, onChange] = React.useState(new Date());

    const [activeAddressType, setActiveAddressType] = React.useState({
        home1: false,
        home2: false,
        home3: false,
    })
    const handleClose = () => {
        setOpen(false);
    };

    const [width, setWidth] = React.useState()
    const [next, setNext] = React.useState(0)

    React.useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth)
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const textStyle = {
        color: ' #EB2830',
        fontFamily: 'var(--font-gotham-black)',
        fontSize: ' 22px',
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: '22px',
        margin: width < 768 && "0"
    }
    const smallText = {
        color: ' var(--Dark_Grey, #32343E)',
        fontFamily: 'var(--font-gotham)',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '900',
        margin: "0 0 0 10px",
        lineHeight: "normal"
    }
    const btnStyle = {
        width: '353px',
        height: '57px',
        borderRadius: '100px',
        background: '#EB252D',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        // marginBotton:"px"
    }
    const btnStyle2 = {
        width: '100%',
        fontFamily: 'var(--font-gotham)',
        borderRadius: '100px',
        background: '#EB252D',
        outline: "none",
        border: "none",
        color: "white",
        fontWeight: "900",
        padding: "20px 0"
    }

    const inputStyle = {
        width: '500px',
        height: '57px',
        borderRadius: '100px',
        background: 'var(--Light_Blue, #F0F5FA)',
        border: "none",
        outline: "none",
        padding: "0 20px",
        color: ' #B5B5B5',
        fontFamily: 'var(--font-gotham)',
        fontSize: ' 14px',
        fontStyle: 'normal',
        fontWeight: '300',

        lineHeight: "normal"

    }
    const btnStyle3 = {
        width: '100px',
        height: '57px',
        borderRadius: '100px',
        background: activeAddressType.home2 && '#EB252D',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: activeAddressType.home2 ? 'var(--white-100, #FFF)' : '#ADADAF',
        fontFamily: 'var(--font-gotham)',
        fontSize: ' 16px',
        fontStyle: 'normal',
        fontWeight: '700',
        margin: width < 768 ? "30px 10px" : "30px 30px",
        cursor: "pointer"

        // marginBotton:"px"
    }
    const btnStyle4 = {
        width: '100px',
        height: '57px',
        borderRadius: '100px',
        background: activeAddressType.home3 && '#EB252D',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: activeAddressType.home3 ? 'var(--white-100, #FFF)' : '#ADADAF',
        fontFamily: 'var(--font-gotham)',
        fontSize: ' 16px',
        fontStyle: 'normal',
        fontWeight: '700',
        margin: width < 768 ? "30px 10px" : "30px 30px",
        cursor: "pointer"

        // marginBotton:"px"
    }

    const navigate = useNavigate()

    const [mapCenter, setMapCenter] = React.useState({ lat: 0, lng: 0 });

    const handleMapClick = (event) => {
        const { latLng } = event;
        const lat = latLng.lat();
        const lng = latLng.lng();
        setSelectedLocation({ lat, lng });
        setMapCenter({ lat, lng });
        // Call the geocoding API to get the address
        fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyABy0de4oAU34qkNVvF4xiiVmvS9zdeiMY`)
            .then(response => response.json())
            .then(data => {
                if (data.results && data.results.length > 0) {
                    console.log(data);
                    setSelectedLocation({ lat, lng });
                    // setEditAddress()
                }
            })
            .catch(error => {
                console.error('Error fetching address:', error);
            });
    };

    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                className='dialog'
                PaperProps={{
                    style: {
                        borderRadius: "28px",
                        background: 'var(--white-100, #FFF)',
                        boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.25)',
                        width: "100%"
                    },
                }}
            >
                <DialogTitle className='d-flex justify-content-end cursor-pointer' id="responsive-dialog-title">
                    <LoadScript googleMapsApiKey="AIzaSyABy0de4oAU34qkNVvF4xiiVmvS9zdeiMY">
                        <GoogleMap
                            mapContainerStyle={{ height: '400px', width: '100%' }}
                            center={mapCenter}
                            zoom={3}
                            onClick={handleMapClick}
                        >
                            {selectedLocation && <Marker position={selectedLocation} />}
                        </GoogleMap>
                    </LoadScript>
                </DialogTitle>
                <DialogContentText style={{ padding: width < 768 && "30px" }}>
                    <div style={{ padding: "0 30px" }}>
                        <div style={textStyle}>
                            Para retirar
                        </div>
                        <div className='d-flex mb-3 mt-3'>
                            <div><img src={Location} /></div>
                            <div style={smallText}>
                                {editAddress}
                            </div>
                        </div>
                    </div>
                </DialogContentText>
                <DialogContentText>
                    <div style={{display:"flex", justifyContent:"center", flexDirection:"column", alignItems:"center"}}>
                        <div style={{ position: "relative", margin: "8px 0" }}>
                            <div>
                                <input value={address.direction}
                                    onChange={(e) => {
                                        setAddress((prevFields) => {
                                            return {
                                                ...prevFields,
                                                direction: e.target.value,
                                            }
                                        })
                                    }}
                                    type='text' style={inputStyle} placeholder='Direccion*' />
                            </div>
                        </div>
                        <div style={{ position: "relative", margin: "8px 0" }} >
                            <div>
                                <input value={address.street}
                                    onChange={(e) => {
                                        setAddress((prevFields) => {
                                            return {
                                                ...prevFields,
                                                street: e.target.value,
                                            }
                                        })
                                    }}
                                    type='text' style={inputStyle} placeholder='Calle*' />
                            </div>
                        </div>
                        <div style={{ position: "relative", margin: "8px 0" }}>
                            <div>
                                <input value={address.additional}
                                    onChange={(e) => {
                                        setAddress((prevFields) => {
                                            return {
                                                ...prevFields,
                                                additional: e.target.value,
                                            }
                                        })
                                    }}
                                    type='text' style={inputStyle} placeholder='Adicional' />
                            </div>
                        </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                        <div onClick={() => {
                            setActiveAddressType({ home1: true, home2: false, home3: false })
                            setAddress({ ...address, type: "casa" })
                        }} style={{
                            width: '100px',
                            height: '57px',
                            borderRadius: '100px',
                            background: activeAddressType.home1 && '#EB252D',
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: activeAddressType.home1 ? 'var(--white-100, #FFF)' : '#ADADAF',
                            fontFamily: 'var(--font-gotham)',
                            fontSize: ' 16px',
                            fontStyle: 'normal',
                            fontWeight: '700',
                            margin: width < 768 ? "30px 10px" : "30px 30px",
                            cursor: "pointer"
                        }}>Casa</div>
                        <div onClick={() => {
                            setActiveAddressType({ home1: false, home2: true, home3: false })
                            setAddress({ ...address, type: "casa" })
                        }} style={btnStyle3}>Casa</div>
                        <div onClick={() => {
                            setActiveAddressType({ home1: false, home2: false, home3: true })
                            setAddress({ ...address, type: "casa" })
                        }} style={btnStyle4}>Casa</div>
                    </div>
                </DialogContentText>
                <DialogActions className='d-flex justify-content-center mb-3'>
                    <div style={btnStyle}>
                        <div><img width={29} height={29} src={LightLocation} /></div>
                        <div onClick={() => setNext(1)} style={{
                            color: 'var(--white-100, #FFF)',
                            fontFamily: 'var(--font-gotham )',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: '700',
                            lineHeight: 'normal',
                        }}>Guardar ubicación </div>
                    </div>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default EditLocationModal;