import React, { useContext, useEffect, useState } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ProductImage from '../assets/zinger.png'
import Slider from 'react-slick'
import Star from '../assets/star.png'
import { useNavigate } from 'react-router-dom'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import GlobalContext from '../context'
import axios from 'axios'
import { API_URL } from '../API/API_URL'

function RelatedProducts({ id }) {

    const [likeItem, setLikeItem] = useState(false);
    const [relatedProducts, setRelatedProducts] = useState([])
    const token = localStorage.getItem("token")
    const navigate = useNavigate()
    const { isAuth, setisAuth } = useContext(GlobalContext)

    const getRelatedProducts = async () => {
        // setLoading(true)
        try {
            const response = await axios.get(`${API_URL}/product-by-category?category_id=${id ? id : 1}`, {
                headers: {
                    Authorization: "Bearer " + token
                }
            })
            if (response.status === 200) {
                // setLoading(false)
                setRelatedProducts(response.data)
                console.log("response", response);
            }
        } catch (error) {
            // setLoading(false)
            console.log("error", error);
        }
    }

    async function likeRelatedProduct(item) {
        try {
            const response = await axios.post(`${API_URL}/like-unlike-product`, {
                product_id: item.id,
                like: !item.like
            }, {
                headers: {
                    Authorization: "Bearer " + token
                }
            })
            if (response.status === 200) {
                getRelatedProducts()
                console.log("response", response);
            }
        } catch (error) {
            console.log("error", error);
        }
    }

    useEffect(() => {
        getRelatedProducts()
    }, [])

    function SampleNextArrow(props) {
        const { className, style, onClick } = props
        return (
            <div
                className={className}
                style={{ ...style, display: 'block', color: 'var(--Dark_Grey, #32343E)', position: 'absolute', top: '180px', right: '-10px' }}
                onClick={onClick}
            >
                <ArrowForwardIcon />
            </div>
        )
    }

    function SamplePrevArrow(props) {
        const { className, style, onClick } = props
        return (
            <div
                className={className}
                style={{ ...style, display: 'block', color: 'var(--Dark_Grey, #32343E)', position: 'absolute', top: '190px', left: '-30px' }}
                onClick={onClick}
            >
                <ArrowBackIcon />
            </div>
        )
    }

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        arrows: true,
        slidesToScroll: 3,
        initialSlide: 0,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,
                }
            },
            {
                breakpoint: 380,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,
                }
            }
        ]
    }

    return (
        <>
            <div className="slider">
                <Slider {...settings}>
                    {relatedProducts && relatedProducts?.map((item, index) => {
                        return (
                            <div className="card position-relative test">
                                <div className="card-body" style={{ cursor: "pointer" }}>
                                    <div style={{ display: "flex", justifyContent: "flex-end" }} onClick={() => {
                                        if (token === null) {
                                            setisAuth("NOT AUTHENTICATED")
                                        }
                                        else {
                                            setisAuth("AUTHENTICATED")
                                            likeRelatedProduct(item)
                                        }
                                    }}>
                                        {item?.like === 0 ? <FavoriteBorderIcon style={{ fontSize: "30px", cursor: "pointer", color: "red" }} /> : <FavoriteIcon style={{ fontSize: "30px", cursor: "pointer", color: "red" }} />}
                                    </div>
                                    <div onClick={() => navigate(`/third/${item.id}`)}>
                                        <img src={item?.image} className='img-fluid' alt='' />
                                        <p className="card-text textshort">{item?.name}</p>
                                        <div>
                                            <p className='card-text check'>
                                                {item?.description.slice(0, 150)}...
                                            </p>
                                        </div>
                                        <div className='mt-5'>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <img src={Star} />
                                                <span style={{ marginLeft: 10 }} className='rating'>{item?.avg_rating}</span>
                                            </div>
                                            <div style={{ borderTopLeftRadius: "20px", width: "200px", textAlign: "center" }} className="position-absolute bottom-0 end-0 top-20 bg-danger p-3 border-top-left-radius-5">
                                                <span className='number text-white text-center'>$ 345.00</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </Slider>
            </div>
        </>
    )


}

export default RelatedProducts