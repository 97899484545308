import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme, } from '@mui/material/styles';
import Cross from "../assets/cut.png"
import Visa from "../assets/visa.png"
import Email from "../assets/Email.png"
import Mastercard from "../assets/Mastercard.png"
import Plus from "../assets/plusCircle.png"
import Right from "../assets/circleright.png"
import Eye from "../assets/eye.png"
// import { makeStyles } from '@material-ui/core';

import Map from "../assets/map.png"
import FB from "../assets/fb.png"
import Google from "../assets/new_gogle.png"
import BannerShort from "../assets/banner_Short.png"
import Logo from "../assets/loginlogo.png"
import rating from "../assets/rating.svg"
import axios from 'axios';
import { API_URL } from '../API/API_URL';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';

function RatingModal({ open, setOpen, trackOrder }) {

    const theme = useTheme();
    const [loading, setLoading] = React.useState(false);
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClose = () => {
        setOpen(false);
    };


    const inputStyle = {
        width: '450px',
        height: '168px',
        borderRadius: "10px",
        border: "1px solid #EEE",
        background: "var(--white-100, #FFF)",
        boxShadow: "15px 20px 45px 0px rgba(233, 233, 233, 0.25)",
        outline: "none",
        padding: "20px",
        color: ' #B5B5B5',
        fontFamily: 'var(--font-gotham)',
        fontSize: ' 14px',
        fontStyle: 'normal',
        fontWeight: '300',
        lineHeight: "normal"
    }


    const btnStyle = {
        width: "308px",
        backgroundColor: 'var(--basic-red)',
        // padding: "19px",
        height: "53px",
        borderRadius: "100px",
        // textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#fff",
        cursor: "pointner",
        margin: "15px"
    }

    const boxStyle1 = {
        width: '327px',
        height: '82px',
        background: 'var(--Light_Blue, #F0F5FA)',
        // border: "2px solid var(--Basic_Red, #E52823)",
        borderRadius: '15px',
        padding: "13px 27px"

    }
    const text = {
        color: '#000',
        fontFamily: 'var(--font-gotham)',
        fontSize: '13px',
        fontStyle: 'normal',
        fontWeight: '900',
        lineHeight: 'normal',
        letterSpacing: '0.65px',
    }
    const redText = {
        color: 'var(--Basic_Red, #E52823)',
        fontFamily: 'var(--font-gotham)',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '900',
        lineHeight: 'normal',
    }

    async function rateOrder() {
        setLoading(true)
        try {
            const response = axios.post(`${API_URL}/rate-order`, {
                order_id: trackOrder.o_id,
                rating: 4
            })
            enqueueSnackbar("Rated successfully", {
                variant: "success",
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            })
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error);
        }
    }

    return (
        <React.Fragment>
            <SnackbarProvider />
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                className='dialog'
                PaperProps={{
                    style: {
                        borderRadius: "28px",
                        background: 'var(--white-100, #FFF)',
                        boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.25)',
                        height: "100vh"
                    },
                }}
            >
                <DialogTitle id="responsive-dialog-title" style={{ padding: "0px", position: 'relative', height: "80vh" }}>
                    <div>
                        <img className='img-fluid' src={BannerShort} />
                    </div>
                    <div style={{ position: 'absolute', top: "20px", right: "20px" }}>
                        <img className='img-fluid' style={{ cursor: "pointer" }} onClick={handleClose} width={27} height={27} src={Cross} alt="cross" />
                    </div>
                    <div style={{ marginTop: "-75px", textAlign: "center" }}>
                        <img width={137} height={184} className='img-fluid' src={Logo} alt='' />
                    </div>
                    <div className='d-flex justify-content-center flex-column align-items-center'>
                        <div style={{
                            fontFamily: 'var(--font-gotham)',
                            color: "#9796A1",
                            fontSize: "15px",
                            fontwe: "900",
                            margin: "42px 0 13px 0"
                        }}>Agradecemos su calificacion!</div>
                        <div style={{
                            fontFamily: 'var(--font-gotham)',
                            color: "#53D776",
                            fontSize: "14px",
                            fontwe: "900",
                            margin: "0 0 23px 0"
                        }}>Orden entregada</div>
                        <div style={{
                            fontFamily: 'var(--font-gotham)',
                            color: "#111719",
                            fontSize: "18px",
                            fontwe: "900",
                            margin: "0 0 25px 0"
                        }}>Califica el servicio ofrecido</div>
                        <div style={{
                            fontFamily: 'var(--font-gotham)',
                            color: "#32343E",
                            fontSize: "22px",
                            fontwe: "900",
                            margin: "0 0 17px 0"
                        }}>Bueno</div>
                        <div style={{
                            margin: "0 0 17px 0"
                        }}>
                            <img src={rating} alt="" />
                            <img src={rating} alt="" />
                            <img src={rating} alt="" />
                            <img src={rating} alt="" />
                            <img src={rating} alt="" />
                        </div>
                        <div style={{
                            fontFamily: 'var(--font-gotham)',
                            color: "#111719",
                            fontSize: "18px",
                            fontwe: "900",
                            margin: "0 0 25px 0"
                        }}>Califica el servicio ofrecido</div>
                        <div style={{
                            fontFamily: 'var(--font-gotham)',
                            color: "#32343E",
                            fontSize: "22px",
                            fontwe: "900",
                            margin: "0 0 17px 0"
                        }}>Bueno</div>
                        <div style={{
                            margin: "0 0 17px 0"
                        }}>
                            <img src={rating} alt="" />
                            <img src={rating} alt="" />
                            <img src={rating} alt="" />
                            <img src={rating} alt="" />
                            <img src={rating} alt="" />
                        </div>
                        <div style={{ margin: "20px 0" }}>
                            <textarea type='text' style={inputStyle} />
                            <p style={{
                                fontFamily: 'var(--font-gotham)',
                                color: "#111719",
                                fontSize: "12px",
                                fontwe: "900",
                                margin: "14px 0 0 0",
                                textAlign: "end"
                            }}>Ver comentarios</p>
                        </div>
                        <div onClick={rateOrder} style={btnStyle}>Ingresar</div>
                    </div>

                    {/* {"Use Google's locatio
                    n service?"} */}
                </DialogTitle>
                <br />
            </Dialog>
        </React.Fragment>
    );
}

export default RatingModal;